import { useEffect, useMemo, useCallback } from 'react';
import { useRouter } from 'next/router';
import { sub, format } from 'date-fns';
import { Badge, IconButton, Typography, Drawer, Stack, Divider, Tooltip } from '@mui/material';

import { useMedicalRecordService, useReservationService } from 'service';
import Navigator from 'libs/navigator';
import useToggle from 'libs/hooks/useToggle';
import getTodayDate from 'libs/date/getTodayDate';
import toastPromise from 'libs/toastPromise';

import Icon from 'components/Icon';
import Clock from 'components/UI/Clock';
import { QueueHead, QueueBoard, QueueList } from './style';
import QueueCardItem from './QueueCardItem';

const ONE_MIN = 60000; // 1 min

export default function QueueDrawer({ userData }) {
  const router = useRouter();
  const { initMedicalRecord } = useMedicalRecordService();
  const { useAllReservation } = useReservationService();
  const [open, toggle] = useToggle(false);

  const { data: queueData, mutate: queueDataMutation } = useAllReservation(
    {
      reserveType: 1,
      startDate: format(sub(new Date(getTodayDate()), { days: 1 }), 'yyyy-MM-dd'),
      endDate: getTodayDate(),
      isComplete: 1,
      isCancel: 0,
      // employeeId: userData.userId,
      limit: 'zero',
      sort: 'start_time asc',
      date: '',
    },
    {
      refreshInterval: ONE_MIN,
    },
  );

  //顯示目前醫師候診中名單
  const [currentDocOwnData, unAssignedDocData] = useMemo(() => {
    const currentDocOwnData = [];
    const unAssignedDocData = [];

    if (!queueData) {
      return [currentDocOwnData, unAssignedDocData];
    }

    (queueData?.list || []).forEach(item => {
      if (item.status !== '候診中') return;

      if (item.employeeId === userData.userId) {
        currentDocOwnData.push(item);
      }

      if (item.employeeId === null) {
        unAssignedDocData.push(item);
      }
    });

    return [currentDocOwnData, unAssignedDocData];
    // eslint-disable-next-line
  }, [queueData]);

  useEffect(() => {
    if (!open) return;

    let timeout = setTimeout(() => queueDataMutation(null), 500);

    return () => clearTimeout(timeout);

    // eslint-disable-next-line
  }, [open]);

  const handleInit = useCallback(
    ({ petIdentityId, memberId, memberReserveId }) =>
      toastPromise(async () => {
        const { id: medicalRecordId } = await initMedicalRecord({
          petIdentityId,
          memberId,
          departmentId: userData.departmentId,
          userId: userData.userId,
          memberReserveId,
        });
        toggle();

        router.push(Navigator.toMedicalRecord(petIdentityId, medicalRecordId) + '&edit=1');
      })(),
    // eslint-disable-next-line
    [userData],
  );

  return (
    <>
      <IconButton onClick={toggle}>
        <Badge badgeContent={currentDocOwnData.length} color="error">
          <Icon iconName="medicalRecord" color="inherit" sx={{ fontSize: '32px' }} />
        </Badge>
      </IconButton>
      <Drawer anchor="right" open={open} onClose={toggle}>
        <QueueBoard>
          <QueueHead>
            <Stack direction="row" spacing={1} alignItems="center">
              <Tooltip title="重新整理">
                <IconButton onClick={() => queueDataMutation(null)}>
                  <Icon iconName="refresh" />
                </IconButton>
              </Tooltip>

              <Typography variant="h4" fontWeight="bolder">
                <Clock />
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="h5" fontWeight="bolder" textAlign="right">
                候診列表
              </Typography>
              <Typography fontWeight="bolder" textAlign="right">
                只顯示候診中
              </Typography>
            </Stack>
          </QueueHead>
          <Typography textAlign="right" mt={1}>
            醫師：{userData.realName}
          </Typography>
          <QueueList>
            {currentDocOwnData.map(item => (
              <QueueCardItem key={`queueDrawer_${item.id}`} item={item} onInit={handleInit} />
            ))}
            <Divider>以下為未分配醫師預約單</Divider>
            {unAssignedDocData.map(item => (
              <QueueCardItem key={`queueDrawer_${item.id}`} item={item} onInit={handleInit} />
            ))}
          </QueueList>
        </QueueBoard>
      </Drawer>
    </>
  );
}
