import { Stack, Tooltip, Chip, Typography } from '@mui/material';

/**
 * @deprecated
 */
export default function DeathStatusIcon({ killUserId, killUserName, killTime }) {
  return killUserId ? (
    <Tooltip
      arrow
      title={
        <Stack fontSize="0.9rem" sx={{ '& p': { fontSize: 'inherit' } }}>
          <Typography>登記人員：{killUserName}</Typography>
          <Typography>登記時間：{new Date(killTime).toLocaleString()}</Typography>
        </Stack>
      }
    >
      <Chip label="已註冊死亡" size="small" color="error" sx={{ mx: 1 }} />
    </Tooltip>
  ) : null;
}
