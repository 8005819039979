import { Chip, DialogContentText, TextField, Button } from '@mui/material';
import { useMemberFormContext } from '../Context';
import Container from './Container';
import Brand from 'components/Brand/Brand';
import { getInputProp } from 'libs/input';
import AddressContainer from './AddressContainer';
import { SelectZip, SelectCounty, SelectCity } from 'components/Input/AutoComplete';
import Icon from 'components/Icon';

export const AddressSection = ({ renderName }) => {
  const { selectionData, onChangeSelectionData } = useMemberFormContext();

  return (
    <AddressContainer>
      <SelectZip
        value={selectionData[`${renderName}AddressZip`]}
        onChange={(e, v) => onChangeSelectionData(`${renderName}AddressZip`, v)}
        preSelectCity={selectionData[`${renderName}AddressCity`]}
        renderInput={p => <TextField {...p} label="郵遞區號" {...getInputProp(`${renderName}AddressZip`)} />}
      />
      <SelectCounty
        value={selectionData[`${renderName}AddressCounty`]}
        onChange={(e, v) => onChangeSelectionData(`${renderName}AddressCounty`, v)}
        preSelectZip={selectionData[`${renderName}AddressZip`]}
        renderInput={p => <TextField {...p} label="縣市" {...getInputProp(`${renderName}AddressCounty`)} />}
      />
      <SelectCity
        value={selectionData[`${renderName}AddressCity`]}
        onChange={(e, v) => onChangeSelectionData(`${renderName}AddressCity`, v)}
        preSelectZip={selectionData[`${renderName}AddressZip`]}
        preSelectCounty={selectionData[`${renderName}AddressCounty`]}
        renderInput={p => <TextField {...p} label="鄉鎮市區" {...getInputProp(`${renderName}AddressCity`)} />}
      />
      <TextField
        label="地址"
        value={selectionData[`${renderName}AddressStreet`]}
        onChange={e => onChangeSelectionData(`${renderName}AddressStreet`, e.target.value)}
        inputProps={getInputProp(`${renderName}AddressStreet`)}
      />
    </AddressContainer>
  );
};

export default function Address() {
  const { setSelectionData } = useMemberFormContext();

  const handleClickSync = () => {
    setSelectionData(prev => ({
      ...prev,
      residenceAddressZip: prev.contactAddressZip,
      residenceAddressCounty: prev.contactAddressCounty,
      residenceAddressCity: prev.contactAddressCity,
      residenceAddressStreet: prev.contactAddressStreet,
    }));
  };

  return (
    <Container>
      <Chip label="地址資訊" color="primary" sx={{ alignSelf: 'flex-start' }} />
      <DialogContentText>郵遞區號 / 縣市 / 鄉鎮市區 會依據內容過濾選項，也可自行輸入</DialogContentText>
      <Brand color="secondary">通訊地址</Brand>
      <AddressSection renderName="contact" />
      <Brand color="secondary">戶籍地址</Brand>
      <AddressSection renderName="residence" />
      <Button variant="outlined" sx={{ alignSelf: 'flex-end' }} startIcon={<Icon iconName="sync" />} onClick={handleClickSync}>
        戶籍地址套用通訊地址
      </Button>
    </Container>
  );
}
