import * as React from 'react';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useManipulateType } from 'service';

export default function SelectManipulateType({ required, ...props }) {
  const { useAllManipulateType } = useManipulateType();
  const { data } = useAllManipulateType();

  return (
    <FormControl fullWidth required={required}>
      <InputLabel>預約類型</InputLabel>
      <Select label="預約類型" {...props}>
        <MenuItem value="">清除</MenuItem>
        {data &&
          data.map(item => (
            <MenuItem key={item.id} value={item.id}>
              <ListItemText>{item.manipulateTypeName}</ListItemText>
              <Typography variant="body2" color="text.secondary">
                {item.description}
              </Typography>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
