import { cloneElement, useEffect, useId, useState } from 'react';
import { useRouter } from 'next/router';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material';
import useToggle from 'libs/hooks/useToggle';
import Form from 'components/Form';
import Icon from 'components/Icon';
import { MemberFormProvider } from './Context';
import { Basic, Address, Description, Company } from './Forms';
import { useMemberService } from 'service';
import notify from 'libs/notify';
import { toOneMember } from 'libs/navPath';
import { pick } from 'lodash';

export default function MemberFormDialog({ button, memberId, defaultOpen }) {
  const router = useRouter();
  const formId = useId();
  const [isOpen, toggle] = useToggle(defaultOpen);

  const isEditing = Boolean(memberId);
  const { useOneMember, addMember, updateMember } = useMemberService();
  const addMemberWithNotify = notify(addMember, {
    success: { render: () => '新增成功，點此前往會員頁面' },
  });
  const updateMemberWithNotify = notify(updateMember, {
    success: { render: () => '儲存成功，請稍後，頁面將重新整理' },
  });
  const { data: member, mutate } = useOneMember(memberId, null, isOpen && isEditing);

  /**
   * use for selector / date-picker
   */
  const [selectionData, setSelectionData] = useState({
    /* the only value(gender.id) to get for API */
    gender: '',
    birthdate: new Date(),
    /* address - for filter and sync action usage */
    contactAddressZip: '',
    contactAddressCounty: '',
    contactAddressCity: '',
    contactAddressStreet: '',
    residenceAddressZip: '',
    residenceAddressCounty: '',
    residenceAddressCity: '',
    residenceAddressStreet: '',
    companyAddressZip: '',
    companyAddressCounty: '',
    companyAddressCity: '',
    companyAddressStreet: '',
  });

  //for editing purpose
  useEffect(() => {
    if (!member) return;

    setSelectionData(prev => ({
      ...prev,
      ...pick(member, Object.keys(prev)),
      birthdate: member.birthdate ? new Date(member.birthdate) : new Date(),
    }));
  }, [member]);

  const handleChangeSelectionData = (name, value) => {
    setSelectionData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (evt, data) => {
    if (memberId) {
      try {
        await updateMemberWithNotify(memberId, {
          ...data,
          genderId: selectionData.gender.id || null,
        })({
          success: {
            delay: 1000,
          },
        });
        mutate();
        toggle();
      } catch (err) {
        console.log(err);
      }
      return;
    }

    try {
      const { memberId } = await addMemberWithNotify({
        ...data,
        genderId: selectionData.gender.id || null,
      })({
        success: {
          onClick: () => router.push(toOneMember(memberId)),
          delay: 1000,
        },
      });
      toggle();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {button ? (
        cloneElement(button, { onClick: toggle })
      ) : (
        <Button
          role="button"
          startIcon={<Icon iconName={memberId ? 'edit' : 'add'} />}
          color="secondary"
          variant="contained"
          disableElevation
          onClick={toggle}
        >
          {memberId ? '編輯會員' : '新增會員'}
        </Button>
      )}
      <Dialog open={isOpen} onClose={toggle} maxWidth="sm" fullWidth role="dialog">
        <DialogTitle>新增會員</DialogTitle>
        <DialogContent>
          <DialogContentText>姓名與電話為必填欄位，電話不能與其他會員重複</DialogContentText>
          <MemberFormProvider
            //using provider to pass down the data
            selectionData={selectionData}
            setSelectionData={setSelectionData}
            onChangeSelectionData={handleChangeSelectionData}
            memberData={member} //only exist when editing
          >
            {(!isEditing || member) && (
              <Form id={formId} onSubmit={handleSubmit}>
                <Basic />
                <Divider />
                <Address />
                <Divider />
                <Description />
                <Divider />
                <Company />
              </Form>
            )}
          </MemberFormProvider>
        </DialogContent>
        <DialogActions>
          <Button type="submit" form={formId}>
            確認
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
