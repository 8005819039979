import differenceInHours from 'date-fns/differenceInHours';
import { Box } from '@mui/material';

const THREE_DAYS_HOURS = 72;
/**
 * @deprecated
 */
export default function NewTag({ time }) {
  const isNew = differenceInHours(new Date(), new Date(time)) < THREE_DAYS_HOURS;

  if (!isNew) {
    return null;
  }

  return (
    <Box
      as="span"
      sx={{
        backgroundColor: 'warning.main',
        color: 'warning.contrastText',
        borderRadius: 1,
        px: 0.5,
        py: 0.2,
        mx: 0.5,
        fontSize: '12px',
        fontWeight: 'bolder',
      }}
    >
      NEW
    </Box>
  );
}
