import { format } from 'date-fns';

/**
 * Please don't use this function anymore
 * reason:
 * 1. this function is mis-understanding dur to the name
 * 2. this function is not suitable for the future
 * 3. this function is really bad
 * test
 * @method formatToISODate
 * @deprecated
 */
export default function formatToISODate(date, withHrMin = false) {
  const formatDate = format(new Date(date), withHrMin ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd');
  return formatDate;
}
