import { cloneElement } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Icon from 'components/Icon/index';

export default function PacsActionButton({ anchor, button }) {
  return (
    <a href={anchor} target="_blank" rel="noreferrer noopener">
      {button ? (
        cloneElement(button)
      ) : (
        <Tooltip title="PACS 醫學影像">
          <IconButton size="small">
            <Icon iconName="pacs" />
          </IconButton>
        </Tooltip>
      )}
    </a>
  );
}
