import { useState } from 'react';

interface Toggler {
  (): void;
  toTrue: () => void;
  toFalse: () => void;
}

export default function useToggle(defaultState = false): [boolean, Toggler] {
  const [state, setState] = useState(defaultState);

  const toggle = () => setState(prev => !prev);
  toggle.toTrue = () => setState(true);
  toggle.toFalse = () => setState(false);

  return [state, toggle];
}
