import { Stack, Chip, TextField, InputAdornment } from '@mui/material';

import { useMemberFormContext } from '../Context';
import Container from './Container';
import { SelectMemberGender } from 'components/Input/AutoComplete';
import { BirthDatePicker } from 'components/Input/DatePicker';
import Brand from 'components/Brand/Brand';
import { getInputProp } from 'libs/input';

export default function Basic() {
  const { selectionData, onChangeSelectionData, memberData } = useMemberFormContext();

  return (
    <Container>
      <Chip label="基本資訊" color="primary" sx={{ alignSelf: 'flex-start' }} />
      <TextField label="名稱" inputProps={getInputProp('memberName')} required defaultValue={memberData?.memberName} />
      <SelectMemberGender
        value={selectionData.gender || null}
        onChange={(e, v) => onChangeSelectionData('gender', v)}
        renderInput={p => <TextField {...p} label="性別" {...getInputProp('genderId')} />}
      />
      <TextField label="英文名稱" inputProps={getInputProp('ename')} defaultValue={memberData?.ename} />
      <BirthDatePicker inputPropName="birthdate" value={selectionData.birthdate} onChange={v => onChangeSelectionData('birthdate', v)} />
      <TextField label="Email" inputProps={getInputProp('email')} defaultValue={memberData?.email} />
      <TextField label="電話" inputProps={getInputProp('phone')} required defaultValue={memberData?.phone} />
      <TextField label="市話" inputProps={getInputProp('homePhone')} defaultValue={memberData?.homePhone} />
      <Brand color="secondary">市內電話</Brand>
      <Stack direction="row" gap={1}>
        <TextField label="區碼" inputProps={getInputProp('localAreaCode')} defaultValue={memberData?.localAreaCode} />
        <TextField label="號碼" inputProps={getInputProp('localPhone')} defaultValue={memberData?.localPhone} />
        <TextField
          label="分機"
          inputProps={getInputProp('localExt')}
          defaultValue={memberData?.localExt}
          InputProps={{
            startAdornment: <InputAdornment position="start">#</InputAdornment>,
          }}
        />
      </Stack>
    </Container>
  );
}
