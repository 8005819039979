import { useRouter } from "next/router";
import { useEffect, useId, useState } from "react";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import Form from "components/Form";
import PetForm from "../Form";
import { usePetDialogContext } from "components/Pet/PetFormDialog/Context";
import { useMemberService, useOldPetService } from "service";
import notify from "libs/notify";
import { toOnePet } from "libs/navPath";
import { pick } from "lodash";

export default function Pet({ petId }) {
  const isEditing = Boolean(petId);
  const router = useRouter();
  const formId = useId();
  const { open, onClose, memberId } = usePetDialogContext();

  const { useOneMember } = useMemberService();
  const { useOnePet, addPet, updatePet } = useOldPetService();
  const { data: member } = useOneMember(memberId, null, open && memberId);
  const { data: pet, mutate } = useOnePet(petId, null, open && petId);

  const addPetWithNotify = notify(addPet, {
    success: { render: () => "新增成功，點此前往寵物頁面" },
  });
  const updatePetWithNotify = notify(updatePet, {
    success: { render: () => "編輯成功，請稍後，頁面將重新整理" },
  });

  /**
   * use for selector / date-picker
   */
  const [selectionData, setSelectionData] = useState({
    /* the values (key.id) to get for API */
    gender: "",
    petType: "",
    petVariety: "",
    petBlood: "",
    birthday: new Date(),
    /* address - for filter and sync action usage */
  });

  useEffect(() => {
    if (!pet) return;

    setSelectionData(prev => ({
      ...prev,
      ...pick(pet, Object.keys(prev)),
      birthday: pet.birthday ? new Date(pet.birthday) : new Date(),
    }));
  }, [pet]);

  const handleChangeSelectionData = (name, value) => {
    setSelectionData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (evt, data) => {
    const prepared = {
      isBirthControl: "0",
      ...data,
      genderId: selectionData.gender.id || null,
      petTypeId: selectionData.petType.id || null,
      petVarietyId: selectionData.petVariety.id || null,
      petBloodId: selectionData.petBlood.id || null,
    };

    if (petId) {
      try {
        await updatePetWithNotify(
          petId,
          prepared,
        )({
          success: {
            delay: 1000,
          },
        });
        onClose();
        mutate();
      } catch (error) {
        console.log(error);
      }

      return;
    }

    try {
      const { id } = await addPetWithNotify({ ...prepared, memberId })({
        success: {
          onClick: () => router.push(toOnePet(id)),
          delay: 1000,
        },
      });
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Dialog open={Boolean(open && memberId && member && (petId ? pet : true))} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{isEditing ? "編輯寵物" : "新增寵物 - 寵物資訊"}</DialogTitle>
      {member && (petId ? pet : true) && (
        <DialogContent>
          <Alert severity="info">
            <Typography variant="body2">會員(飼主)：{member.memberName}</Typography>
            <Typography variant="body2">
              擁有寵物：
              {member.pets.map(pet => `${pet.petName}(${pet?.petType?.typeName || "?"})`).join(", ")}
            </Typography>
          </Alert>
          <Form id={formId} onSubmit={handleSubmit}>
            <PetForm
              petData={pet} //for editing
              selectionData={selectionData}
              onChangeSelectionData={handleChangeSelectionData}
            />
          </Form>
        </DialogContent>
      )}
      <DialogActions>
        <Button type="submit" form={formId}>
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
}
