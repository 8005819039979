import { forwardRef, cloneElement } from "react";
import { format, parseISO } from "date-fns";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import InfoIcon from "@mui/material/Icon";
import Chip from "@mui/material/Chip";

import SendIcon from "@mui/icons-material/Send";

import useToggle from "libs/hooks/useToggle";
import usePaginator from "libs/hooks/usePaginator";

import Paginator from "components/UI/Paginator";

import { useConfigService } from "service/config/index";
import { useSmsService } from "service/sms";

type SmsListDialogPropsType = {
  isOpen: boolean;
  memberId: string;
  phone: string;
  petId?: string;

  button?: React.ReactElement;
};

const windowFeatures = "popup,width=1300,height=800,toolbar=no,menubar=no,location=no";

const formatDateTime = (dateString: string) => {
  const date = parseISO(dateString);
  return format(date, "yyyy-MM-dd HH:mm:ss");
};
// end of formatDateTime

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// end of Transition

export default function SmsListDialog(props: SmsListDialogPropsType) {
  const [open, toggle] = useToggle(props.isOpen);
  const [page, handleChangePage] = usePaginator(1);

  const { useAllConfig } = useConfigService();
  const { data: config } = useAllConfig();

  const { useAllSms } = useSmsService();
  const { data, isLoading } = useAllSms({
    memberId: props.memberId,
    sendPhone: props.phone,
    page: String(page),
    limit: "10",
    isAble: Boolean(open),
  });

  const handleGoToBonsale = () => {
    const fullUrl = window.location.href;
    const subdomain = new URL(fullUrl).hostname.split(".")[0];

    const clientName = subdomain === "dev" ? "beta" : config?.client_name;
    const baseUrl = process.env.NODE_ENV === "development" ? "http://localhost:5173" : `https://${clientName}.bonsale.info`;
    const targetUrl = `${baseUrl}/sms/send?memberId=${props.memberId}&petId=${props.petId}`;

    window.open(targetUrl, "_blank", windowFeatures);
  };

  return (
    <>
      {props.button && cloneElement(props.button, { onClick: toggle })}

      <Dialog fullWidth maxWidth="laptop" open={open} TransitionComponent={Transition} onClose={toggle}>
        <DialogTitle>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">簡訊紀錄</Typography>

            <Button variant="contained" endIcon={<SendIcon />} size="small" onClick={handleGoToBonsale}>
              傳送簡訊
            </Button>
          </Stack>
        </DialogTitle>
        {isLoading && <LinearProgress />}

        <DialogContent>
          {data?.totalCount === 0 ? (
            <Alert
              severity="info"
              icon={<InfoIcon />}
              sx={{
                my: 1,
                "& .MuiAlert-message": {
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1rem",
                },
              }}
            >
              <Stack spacing={1}>
                <Typography variant="body1">目前尚無簡訊紀錄</Typography>
                <Typography variant="body2" color="text.secondary">
                  電話號碼：{props.phone}
                </Typography>
              </Stack>
            </Alert>
          ) : (
            <>
              <Stack alignItems="flex-end" my={1}>
                <Paginator givenDataTotalPage={data?.totalPage} onChange={handleChangePage} />
              </Stack>

              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">單號</TableCell>
                      <TableCell align="center">會員</TableCell>
                      <TableCell align="center">電話</TableCell>
                      <TableCell align="center" width="20%">
                        建立時間 / 發送時間
                      </TableCell>
                      <TableCell align="center">內容</TableCell>
                      <TableCell align="center">狀態</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {data?.list?.map(item => (
                      <TableRow key={item.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell component="th" scope="row" align="center">
                          {item.transNo}
                        </TableCell>

                        <TableCell align="center">{item.member.memberName}</TableCell>
                        <TableCell align="center">{item.sendPhone}</TableCell>
                        <TableCell align="center">{`${formatDateTime(item.createTime)} / ${formatDateTime(item.tranDate)}`}</TableCell>

                        <TableCell>
                          <Tooltip title={item.sendContent}>
                            <Typography
                              variant="body2"
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "200px",
                              }}
                            >
                              {item.sendContent}
                            </Typography>
                          </Tooltip>
                        </TableCell>

                        <TableCell align="center">
                          <Chip
                            label={item.responseCode === "success" ? "成功" : "失敗"}
                            color={item.responseCode === "success" ? "success" : "error"}
                            size="small"
                            variant="filled"
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={toggle}>取消</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
