import Link from 'next/link';
import { cloneElement } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Icon from 'components/Icon/index';
import { toOneMember } from 'libs/navPath';

export default function MemberInfoActionButton({ memberId, button }) {
  return (
    <Link href={toOneMember(memberId)}>
      {button ? (
        cloneElement(button)
      ) : (
        <Tooltip title="更多資訊">
          <IconButton size="small">
            <Icon iconName="member" color="primary" />
          </IconButton>
        </Tooltip>
      )}
    </Link>
  );
}
