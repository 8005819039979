import { useState, useCallback } from 'react';

export default function useForm(initValue) {
  const [formData, setFormData] = useState(initValue);

  const handleChange = useCallback(evt => {
    const { name, value } = evt.target;
    setFormData(prev => ({ ...prev, [name]: value }));

    // eslint-disable-next-line
  }, []);

  return [formData, handleChange, setFormData];
}
