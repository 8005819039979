import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { motion, LayoutGroup, MotionConfig, AnimatePresence } from 'framer-motion';

import { styled } from '@mui/system';
import Box from '@mui/material/Box';

import Menu from 'components/Menu';
import Heading from 'components/Heading';

import { AuthProvider } from 'components/Provider/AuthProvider';

const MotionBox = motion(Box);

const Layout = styled('div')(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('tablet')]: {
    flexDirection: 'row',
  },
  overflow: 'auto',
}));

const Container = styled(motion.div)(() => ({
  width: '100%',
  height: '100%',
  maxWidth: '1528px',
  margin: '0 auto',
  padding: '0 12px',
  display: 'flex',
  flexDirection: 'column',
  '&:before': {
    background: '#FFFFFF7F',
    content: '" "',
    position: 'absolute',
    width: '100%',
    height: 'calc(100% - 58px)',
    left: 0,
    bottom: 0,
  },
}));

/**
 * @component
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {?string} props.title
 */
export default function MainPageLayout({ children, title }) {
  const router = useRouter();

  return (
    <>
      <Head>
        <title>{`Dr.Vet | ${title || '病歷管理系統'}`}</title>
      </Head>

      <AuthProvider>
        <Layout>
          <MotionConfig
            transition={{
              duration: 1,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <LayoutGroup>
              <Menu title={title} />

              <Container layout>
                <motion.div layout style={{ flex: '0 1 auto', position: 'sticky', top: 0, zIndex: 6, backgroundColor: '#E5E5E5' }}>
                  <Heading title={title} />
                </motion.div>

                <AnimatePresence mode="wait">
                  <MotionBox
                    key={router.pathname}
                    layout
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.4 }}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      // flex: '1 1 auto',
                      // overflowY: 'auto',
                      height: 'calc(100% - 58px)',
                      padding: '8px 0 8px 0',
                      position: 'relative',
                    }}
                  >
                    {children}
                  </MotionBox>
                </AnimatePresence>
              </Container>
            </LayoutGroup>
          </MotionConfig>
        </Layout>
      </AuthProvider>
    </>
  );
}
