import { motion } from "framer-motion";
import { styled } from "@mui/system";
import { is } from "date-fns/locale";

export const PreSpace = styled(motion.div, { shouldForwardProp: prop => prop !== "isOpen" })(({ theme, isOpen }) => ({
  [theme.breakpoints.down("tablet")]: {
    width: "100%",
    height: isOpen ? "100vh" : 40,
    // marginBottom: isOpen ? '100vh' : 40,
    height: 40,
    marginBottom: 40,
  },
  [theme.breakpoints.up("tablet")]: {
    // width: isOpen ? 240 : 56,
    width: 56,
    height: "100%",
  },
}));

export const Container = styled(motion.div, { shouldForwardProp: prop => prop !== "isOpen" })(({ theme, isOpen }) => ({
  overflow: "hidden",
  backgroundColor: "white",
  boxShadow: isOpen ? "2px 2px 10px 2px #00000020" : "unset",
  // position: 'fixed',
  position: "absolute",
  top: 0,
  zIndex: 99,
  [theme.breakpoints.down("tablet")]: {
    width: "100%",
    height: isOpen ? "100vh" : 40,
  },
  [theme.breakpoints.up("tablet")]: {
    width: isOpen ? 240 : 56,
    height: "100%",
  },
}));

export const SubContainer = styled(motion.div)(({ theme }) => ({
  display: "flex",
  gap: 8,
  height: "100%",

  [theme.breakpoints.down("tablet")]: {
    flexDirection: "column",
    "& .MuiList-root:first-of-type": {
      "& > *:first-of-type": {
        flexDirection: "row",
        alignItems: "center",
        paddingRight: 12,
        "& .MuiListItemText-root": {
          display: "none",
        },
        "& p": {
          display: "block",
        },
      },
      "& > *:nth-of-type(n+2):nth-of-type(-n+3)": {
        display: "none",
      },
    },
    "& .MuiDivider-root:first-of-type": {
      display: "none",
    },
  },
  [theme.breakpoints.up("tablet")]: {
    width: 240,
    height: "100%",
    padding: "8px 0",
    flexDirection: "column",
    alignItems: "flex-start",
    "& .MuiList-root": {
      width: "100%",
      "& .MuiListItemButton-root": {
        padding: "8px 0px 8px 16px",
      },
    },
    "& .MuiDivider-root": {
      marginLeft: 8,
      marginRight: 8,
    },
  },
}));

export const Footer = styled(motion.div)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  gap: 4,
  "& .footer__doctorName": {
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
  [theme.breakpoints.down("tablet")]: {
    padding: "0px 16px 8px",
    "& .footer__doctorName": {
      maxWidth: "40ch",
    },
  },
  [theme.breakpoints.up("tablet")]: {
    padding: "0 12px",
    "& .footer__doctorName": {
      width: "13ch",
    },
  },
}));
