import { useRouter } from 'next/router';
import { styled } from '@mui/system';
import { Divider, Stack, Typography, Button } from '@mui/material';
import SearchDialog from 'components/Tool/SearchDialog';
import { useMe } from 'components/Provider/AuthProvider';
import QueueDrawer from 'components/Tool/QueueDrawer/index';

const Container = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',

  [theme.breakpoints.down('tablet')]: {
    height: 50,
    '& > *:first-of-type': {
      display: 'none',
    },
    '& > *:nth-of-type(2)': {
      flex: 1,
    },
  },
  [theme.breakpoints.up('tablet')]: {
    height: 58,
    '& > *:first-of-type': {
      flex: 1,
    },
    '& > *:nth-of-type(2)': {
      display: 'none',
    },
  },
}));

export default function Heading({ title, actions }) {
  const router = useRouter();
  const me = useMe();

  const department = me.departmentId ? me.department.name : null;
  const salePoint = me.salePointId ? me.salePoint.salePointName : null;
  const hasSameName = department === salePoint && department;
  const subTitle = title ? hasSameName || `${department} / ${salePoint}` : 'WELCOME BACK';
  const mainTitle = title || department;

  return (
    <>
      <Container>
        <Stack sx={{ '& > *': { lineHeight: 1.35 } }}>
          <Typography variant="body2">{subTitle}</Typography>
          <Typography variant="h6" fontWeight="bolder">
            {mainTitle}
          </Typography>
        </Stack>
        <Stack direction="row">
          <Button size="small" onClick={() => router.back()}>
            上一頁
          </Button>
          <Button size="small" onClick={() => router.reload()}>
            重新整理
          </Button>
        </Stack>
        <Stack direction="row">
          {actions}
          <QueueDrawer userData={me} />
          <SearchDialog />
        </Stack>
      </Container>
    </>
  );
}
