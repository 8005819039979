import { useHospDataService, useOldPetService } from 'service';

export default function usePacsURL(petName, petCode, petCustomCode, petId) {
  const { useOnePet } = useOldPetService();
  const { useConfig } = useHospDataService();
  const { data: configData } = useConfig();
  const { data: pet } = useOnePet(petId, null, Boolean(petId));

  const code = pet?.petCode || petCode;
  const customCode = pet?.petCustomCode || petCustomCode;
  const name = pet?.petName || petName;

  const hadCustomCode = Boolean(configData?.['petCode.format'] || configData?.['petCode.seq']);

  if (configData?.pacsUrl) {
    let PACS_URL = `${configData.pacsUrl}/?sortBy=studyDate&sortDirection=ascending`;

    if (hadCustomCode && customCode) {
      PACS_URL += `&mrn=${customCode}`;
      return PACS_URL;
    }

    if (code) {
      PACS_URL += `&mrn=${code.slice(2)}`;
      return PACS_URL;
    }

    if (name) {
      PACS_URL += `&patientName=${name}`;
      return PACS_URL;
    }

    return PACS_URL;
  }

  return null;
}
