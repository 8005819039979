import { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

const StyledSvg = styled(motion.svg)(() => ({
  width: 40,
  height: 40,
}));

const StyledCircle = styled(motion.circle)(({ theme }) => ({
  strokeWidth: "3px",
  strokeLinecap: "round",
  stroke: theme.palette.primary.main,
  fill: "transparent",
}));

const variants = {
  hiddenIn: { pathLength: 0, opacity: 0, scale: 1 },
  visible: {
    pathLength: 1,
    opacity: 1,
    scale: 1,
    transition: {
      opacity: { duration: 0.01 },
      pathLength: { type: "tween", duration: 1, ease: [0.82, 0.04, 0.83, 0.67] },
    },
  },
  hiddenOut: {
    pathLength: 1,
    opacity: 0,
    scale: 1.2,
    transition: {
      opacity: { delay: 0.1, duration: 0.6 },
      scale: { delay: 0.1, duration: 0.4, ease: "easeOut" },
    },
  },
};

type Phrase = keyof typeof variants;

type Props = {
  show: boolean;
  onTransitionEnd?(): void;
};

export default function Cursor({ show, onTransitionEnd }: Props) {
  const ref = useRef<HTMLElement>(null);

  const [phase, setPhase] = useState<Phrase>("hiddenIn");

  useEffect(() => {
    if (!ref.current) return;
    function followCursor(event: MouseEvent) {
      ref.current!.setAttribute("style", `top: ${event.pageY + 5}px; left: ${event.pageX + 5}px`);
    }

    if (show) {
      document.addEventListener("mousemove", followCursor);
    }

    return () => {
      document.removeEventListener("mousemove", followCursor);
    };
  }, [show]);

  useEffect(() => {
    if (show) {
      setPhase("visible");
    }

    if (!show) {
      setPhase("hiddenIn");
    }
  }, [show]);

  const handleAnimationComplete = (event: string) => {
    if (event === "hiddenIn") return;

    if (event === "hiddenOut") {
      setPhase("hiddenIn");
      return;
    }

    setPhase("hiddenOut");
    onTransitionEnd?.();
  };

  return (
    <MotionBox layout ref={ref} sx={{ position: "fixed", zIndex: 9999, width: "40px", height: "40px", transform: "rotate(-135deg)" }}>
      <StyledSvg viewBox="0 0 50 50" animate={phase} onAnimationComplete={handleAnimationComplete}>
        <StyledCircle layout cx="25" cy="25" r="15" variants={variants} />
      </StyledSvg>
    </MotionBox>
  );
}
