import { useRouter } from 'next/router';
import { motion } from 'framer-motion';
import { Collapse, List } from '@mui/material';
import MenuButton from '../MenuButton';
import useToggle from 'libs/hooks/useToggle';
import Icon from 'components/Icon';
import MenuLink from '../MenuLink';

const MotionIcon = motion(Icon);

export default function DropdownMenu({ link, userAccess }) {
  const router = useRouter();
  const [isOpen, toggle] = useToggle();

  return (
    <>
      <MenuButton
        iconName={link.iconName}
        displayName={link.displayName}
        onClick={toggle}
        active={router.route.includes(link.name)}
        subIcon={
          <MotionIcon
            iconName="arrowDropDown"
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.2 }}
            sx={{ color: 'gray', mr: 1 }}
          />
        }
      />
      <Collapse in={isOpen}>
        <List sx={{ backgroundColor: '#00000010' }}>
          {link.links.map(subLink => {
            if (subLink.access) {
              if (subLink.access !== userAccess) return null;
            }
            return <MenuLink key={subLink.name} link={subLink} />;
          })}
        </List>
      </Collapse>
    </>
  );
}
