import { Button, DialogActions } from '@mui/material';

export default function CustomActionBar(props) {
  return (
    <DialogActions>
      <Button onClick={props.onCancel}>取消</Button>
      <Button onClick={props.onAccept}>確認</Button>
    </DialogActions>
  );
}
