/**
 * @deprecated
 */
export { default as ReservationActionButton } from './Reservation';
/**
 * @deprecated
 */
export { default as MedicalRecordActionButton } from './MedicalRecord';
/**
 * @deprecated
 */
export { default as InfoActionButton } from './Info/Common';
/**
 * @deprecated
 */
export { default as PetInfoActionButton } from './Info/Pet';
/**
 * @deprecated
 */
export { default as MemberInfoActionButton } from './Info/Member';
/**
 * @deprecated
 */
export { default as EditActionButton } from './Edit';
/**
 * @deprecated
 */
export { default as PacsActionButton } from './Pacs';
