import { useState, useCallback } from 'react';

export default function usePaginator(initPage = 1) {
  const [page, setPage] = useState(initPage);

  const handleChange = useCallback((event, value) => {
    setPage(value);

    // eslint-disable-next-line
  }, []);

  return [page, handleChange];
}
