import { useState, useId, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Link as NavLink,
} from '@mui/material';
import Form from 'components/Form';
import Icon from 'components/Icon';
import { SelectPetType } from 'components/Input/AutoComplete';
import { useMemberService, useOldPetService } from 'service';
import { getInputProp } from 'libs/input';
import useToggle from 'libs/hooks/useToggle';
import notify from 'libs/notify';
import { toMemberList, toOneMember, toPetList } from 'libs/navPath';
import useDebounce from 'libs/hooks/useDebounce';

export default function QuickFormDialog() {
  const router = useRouter();
  const formId = useId();
  const { useAllMember, addMember } = useMemberService();
  const { useAllPet } = useOldPetService();
  const addMemberWithNotify = notify(addMember, {
    success: { render: () => '儲存成功，點此前往會員頁面' },
  });
  const [open, toggle] = useToggle();
  const [petTypeId, setPetTypeId] = useState('');
  const [memberPhone, setMemberPhone] = useState('');
  const [petName, setPetName] = useState('');
  const [delayMemberPhone, setDelayMemberPhone] = useState('');
  const [delayPetName, setDelayPetName] = useState('');

  useDebounce(() => setDelayMemberPhone(memberPhone), 500, [memberPhone]);
  useDebounce(() => setDelayPetName(petName), 500, [petName]);

  const { data: allMemberData } = useAllMember({ phone: delayMemberPhone }, null, delayMemberPhone);
  const isNotNewMember = Boolean(delayMemberPhone && allMemberData?.totalCount > 0);

  const { data: allPetData } = useAllPet({ petName: delayPetName }, null, delayPetName);
  const hasSameNamePet = Boolean(delayPetName && allPetData?.totalCount > 0);

  const handleSubmit = async (evt, data) => {
    const prepareData = {
      phone: data.phone,
      memberName: data.memberName,
      pets: [
        {
          petName: data.petName,
          petTypeId,
        },
      ],
    };

    try {
      const { memberId } = await addMemberWithNotify(prepareData)({
        success: {
          onClick: () => router.push(toOneMember(memberId)),
          delay: 1000,
        },
      });
      toggle();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    return () => {
      setMemberPhone('');
      setPetTypeId('');
    };
  }, [open]);

  return (
    <>
      <Button startIcon={<Icon iconName="add" />} variant="contained" disableElevation onClick={toggle}>
        簡易新增
      </Button>
      <Dialog open={open} onClose={toggle} maxWidth="sm" fullWidth>
        <DialogTitle>簡易新增</DialogTitle>
        <DialogContent>
          <DialogContentText>快速新增會員以及寵物</DialogContentText>
          {isNotNewMember && (
            <Alert severity="error" variant="filled">
              <Link href={toMemberList() + `?phone=${memberPhone}`} legacyBehavior>
                <NavLink underline="hover" color="inherit">
                  此電話已存在，點此前往查看。
                </NavLink>
              </Link>
            </Alert>
          )}
          {hasSameNamePet && (
            <Alert severity="warning" sx={{ my: 1 }} variant="filled">
              <Link href={toPetList + `?petName=${petName}`} legacyBehavior>
                <NavLink underline="hover" color="warning">
                  有同名的寵物，點此前往查看，或仍繼續新增。
                </NavLink>
              </Link>
            </Alert>
          )}
          <Form id={formId} onSubmit={handleSubmit} required disabled={isNotNewMember}>
            <TextField label="會員電話" inputProps={getInputProp('phone')} onChange={e => setMemberPhone(e.target.value)} />
            <TextField label="會員名稱" inputProps={getInputProp('memberName')} />
            <TextField label="寵物名稱" inputProps={getInputProp('petName')} onChange={e => setPetName(e.target.value)} />
            <SelectPetType
              onChange={(e, n) => setPetTypeId(n.id)}
              renderInput={p => <TextField {...p} label="寵物類別" {...getInputProp('petTypeId')} required />}
            />
          </Form>
        </DialogContent>
        <DialogActions>
          <Button type="submit" form={formId} disabled={isNotNewMember}>
            確認
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
