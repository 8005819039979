import { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import { useRouter } from "next/router";
import { styled } from "@mui/system";
import { Stack, TextField, Paper, Button, LinearProgress, Alert } from "@mui/material";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import MemberListItem from "components/Member/ListItem";
import PetListItem from "components/Pet/ListItem";

import { useOldPetService, useMemberService } from "service";

import PATH from "libs/path";
import useForm from "libs/hooks/useForm";
import getQueryString from "libs/getQueryString";
import { toMemberList } from "libs/navPath";

const FieldContainer = styled(Stack)({
  flexDirection: "row",
  justifyContent: "space-between",
  gap: 8,
});

const DataContainer = styled(Stack)({});

const SizedTextField = styled(props => <TextField fullWidth size="small" {...props} />)({});

export const PetForm = forwardRef(({ defaultValue = null, onEnter }, ref) => {
  const petFormInitRef = useRef(true);
  const [formData, handleChange, setFormData] = useForm(
    defaultValue ?? {
      petName: "",
      phone: "",
      icCard: "",
      petCode: "",
    },
  );

  useEffect(() => {
    if (petFormInitRef.current) {
      petFormInitRef.current = false;
      return;
    }

    setFormData(
      defaultValue ?? {
        petName: "",
        phone: "",
        icCard: "",
        petCode: "",
      },
    );

    //eslint-disable-next-line
  }, [defaultValue]);

  useImperativeHandle(ref, () => ({
    getFormData: () => formData,
  }));

  const handlePressEnter = e => {
    if (e.key === "Enter") {
      onEnter?.();
    }
  };

  return (
    <>
      <SizedTextField label="寵物名字" name="petName" value={formData.petName} onChange={handleChange} onKeyPress={handlePressEnter} />
      <SizedTextField label="寵物代號" name="petCode" value={formData.petCode} onChange={handleChange} onKeyPress={handlePressEnter} />
      <SizedTextField label="會員電話" name="phone" value={formData.phone} onChange={handleChange} onKeyPress={handlePressEnter} />
      <SizedTextField label="寵物晶片" name="icCard" value={formData.icCard} onChange={handleChange} onKeyPress={handlePressEnter} />
    </>
  );
});
PetForm.displayName = "PetForm";

export const MemberForm = forwardRef(({ defaultValue, onEnter }, ref) => {
  const memberFormInitRef = useRef();
  const [formData, handleChange, setFormData] = useForm(
    defaultValue ?? {
      name: "",
      phone: "",
      petName: "",
    },
  );

  useImperativeHandle(ref, () => ({
    getFormData: () => formData,
  }));

  useEffect(() => {
    if (memberFormInitRef.current) {
      memberFormInitRef.current = false;
      return;
    }

    setFormData(
      defaultValue ?? {
        name: "",
        phone: "",
        petName: "",
      },
    );
    //eslint-disable-next-line
  }, [defaultValue]);
  const handlePressEnter = e => {
    if (e.key === "Enter") {
      onEnter?.();
    }
  };
  return (
    <>
      <SizedTextField label="會員名字" name="name" value={formData.name} onChange={handleChange} onKeyPress={handlePressEnter} />
      <SizedTextField label="會員電話" name="phone" value={formData.phone} onChange={handleChange} onKeyPress={handlePressEnter} />
      <SizedTextField label="寵物名字" name="petName" value={formData.petName} onChange={handleChange} onKeyPress={handlePressEnter} />
    </>
  );
});
MemberForm.displayName = "MemberForm";

export const PetSearchField = () => {
  const router = useRouter();
  const formRef = useRef(null);
  const [queryOpts, setQueryOpts] = useState(null);
  const { useAllPet } = useOldPetService();
  const { data: petsData, isLoading } = useAllPet({ limit: 7, deleted: 0, ...queryOpts }, null, queryOpts);

  const handleClick = () => {
    const formData = formRef.current.getFormData();
    if (!formData.petName && !formData.phone && !formData.icCard && !formData.petCode) {
      alert("要填入一些內容才好搜尋喔>_<");
      return;
    }
    setQueryOpts(formRef.current.getFormData());
  };

  const handleNavToListPage = () => {
    const queryString = getQueryString(queryOpts);
    router.push(PATH.toPetList() + queryString);
  };

  return (
    <>
      <FieldContainer>
        <PetForm ref={formRef} onEnter={handleClick} />
      </FieldContainer>
      <Button variant="contained" fullWidth onClick={handleClick}>
        搜尋
      </Button>
      <DataContainer>
        {queryOpts && isLoading && <LinearProgress />}
        {petsData && (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>寵物代號</TableCell>
                  <TableCell>寵物名字</TableCell>
                  <TableCell>寵物種類</TableCell>
                  <TableCell>飼主名稱</TableCell>
                  <TableCell>飼主電話</TableCell>
                  <TableCell>動作</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {petsData.list.map(pet => (
                  <PetListItem key={pet.id} pet={pet} />
                ))}
                {petsData.list.length !== 0 && (
                  <TableRow>
                    <TableCell align="right" colSpan={7}>
                      <Button endIcon={<SearchIcon />} onClick={handleNavToListPage}>
                        查看更多
                      </Button>
                    </TableCell>
                  </TableRow>
                )}

                {/* if no data */}
                {petsData.list.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Alert severity="info" color="info" variant="outlined">
                        查無此寵物
                      </Alert>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataContainer>
    </>
  );
};

export const MemberSearchField = () => {
  const router = useRouter();
  const formRef = useRef(null);
  const [queryOpts, setQueryOpts] = useState(null);
  const { useAllMember } = useMemberService();
  const { data: membersData, isLoading } = useAllMember({ limit: 7, sort: "select_time desc", deleted: 0, ...queryOpts }, null, queryOpts);

  const handleClick = () => {
    const formData = formRef.current.getFormData();
    if (!formData.name && !formData.phone && !formData.petName) {
      alert("要填入一些內容才好搜尋喔>_<");
      return;
    }
    setQueryOpts(formRef.current.getFormData());
  };

  const handleNavToListPage = () => {
    const queryString = getQueryString(queryOpts);
    router.push(toMemberList() + queryString);
  };

  return (
    <>
      <FieldContainer>
        <MemberForm ref={formRef} onEnter={handleClick} />
      </FieldContainer>
      <Button variant="contained" fullWidth onClick={handleClick}>
        搜尋
      </Button>
      <DataContainer>
        {queryOpts && isLoading && <LinearProgress />}
        {membersData && (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>會員代號</TableCell>
                  <TableCell>會員名字</TableCell>
                  <TableCell>會員性別</TableCell>
                  <TableCell>會員電話</TableCell>
                  <TableCell>其他電話</TableCell>
                  <TableCell>地址</TableCell>
                  <TableCell>動作</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {membersData.list.map(member => (
                  <MemberListItem key={member.id} member={member} />
                ))}
                {membersData.list.length !== 0 && (
                  <TableRow>
                    <TableCell align="right" colSpan={7}>
                      <Button endIcon={<SearchIcon />} onClick={handleNavToListPage}>
                        查看更多
                      </Button>
                    </TableCell>
                  </TableRow>
                )}

                {/* if no data */}
                {membersData.list.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Alert severity="info" color="info" variant="outlined">
                        查無此人
                      </Alert>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataContainer>
    </>
  );
};
