import { styled } from '@mui/system';
import { Box } from '@mui/material';
import { Children, cloneElement } from 'react';

const AddressGrid = styled(Box)({
  display: 'grid',
  gap: 8,
  gridTemplateAreas: `
    "zip county city"
    "street street street"
  `,
  gridAutoColumns: '1fr 1fr 1fr',
  '& > .address-grid-1': {
    gridArea: 'zip',
  },
  '& > .address-grid-2': {
    gridArea: 'county',
  },
  '& > .address-grid-3': {
    gridArea: 'city',
  },
  '& > .address-grid-4': {
    gridArea: 'street',
  },
});

export default function AddressContainer({ children, ...props }) {
  if (Children.count(children) !== 4)
    throw new Error('the container was only designed for 4 children');

  return (
    <AddressGrid {...props}>
      {Children.map(children, (child, index) =>
        cloneElement(child, { ...child.prop, className: `address-grid-${index + 1}` }),
      )}
    </AddressGrid>
  );
}
