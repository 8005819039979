import { cloneElement } from 'react';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import Icon from 'components/Icon';

import SmsListDialog from 'features/Sms/SmsListDialog';

export default function SmsActionButton({ memberId, phone, petId, button, props }) {

  return (
    <SmsListDialog
      {...props}
      isOpen={false}
      memberId={memberId}
      phone={phone}
      petId={petId}
      button={
        button ? (
          cloneElement(button)
        ) : (
          <Tooltip title="簡訊紀錄">
            <IconButton size="small" sx={{
              color: 'error.dark',
            }}>
              <Icon iconName="smsIcon" />
            </IconButton>
          </Tooltip>
        )
      }
    />
  );
}
