/**
 * @deprecated
 */
export const toMemberList = () => '/member/list';
/**
 * @deprecated
 */
export const toOneMember = memberId => `/member/${memberId}`;
/**
 * @deprecated
 */
export const toPetList = () => '/pet/list';
/**
 * @deprecated
 */
export const toOnePet = petId => `/pet/${petId}`;
/**
 * @deprecated
 */
export const toMedicalRecord = (petId, medicalRecordId) => {
  let path = `/medical-record?petId=${petId}`;

  if (medicalRecordId) {
    path += `&mrId=${medicalRecordId}`;
  }

  return path;
};
