import React from 'react';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Icon from 'components/Icon';

/**
 * @component
 * @param  {Object} props
 * @param  {string} props.iconName
 * @param  {?string} props.displayName
 * @param  {?function} props.onClick
 * @param  {?Object} ref
 */
function MenuButton(props, ref) {
  return (
    <ListItemButton onClick={props?.onClick} ref={ref} selected={props.active}>
      <ListItemIcon>
        <Icon iconName={props.iconName} sx={{ color: props.active ? 'success.main' : 'unset' }} />
      </ListItemIcon>
      <ListItemText primary={props.displayName} />

      {props.subIcon}
    </ListItemButton>
  );
}

/**@see {@link MenuButton} */
export default React.forwardRef(MenuButton);
