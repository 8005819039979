import { styled } from '@mui/system';
import { Stack, Box, Paper } from '@mui/material';

export const QueueBoard = styled(Box)({
  width: 370,
  padding: '8px 16px',
  backgroundColor: '#EFEFEF',
  flex: 1,
});

export const QueueHead = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '8px 0',
});

export const QueueList = styled(Stack)({
  marginTop: 16,
  gap: 16,
});

export const QueueCard = styled(Paper)({
  width: '100%',
  minHeight: 82,
  display: 'flex',
  flexDirection: 'column',
  padding: '4px 16px 8px',
  gap: 10,
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.03)',
  },
});

export const QueueCardHead = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  gap: 12,
});

export const QueueCardFoot = styled(Stack)({
  flexDirection: 'row',
  gap: 8,
  alignItems: 'center',
});
