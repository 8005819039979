import { isNull } from 'lodash';
import { format, intervalToDuration } from 'date-fns';

/**
 * @deprecated
 */
export default class Pet {
  #data;

  constructor(data) {
    this.#data = data;
  }

  get petId() {
    return this.#data.id;
  }

  get petName() {
    return this.#data.petName;
  }

  get petCode() {
    return {
      default: this.#data.petCode,
      custom: this.#data.originPetCode,
    };
  }

  get petChip() {
    return this.#data.icCard;
  }

  get birthday() {
    if (!this.#data.birthday) {
      return {
        date: null,
        age: null,
        format: '未登記',
      };
    }

    const today = new Date();
    const birthday = new Date(this.#data.birthday);
    const duration = intervalToDuration({ start: birthday, end: today });
    const formatBirthday = format(birthday, 'yyyy-MM-dd');
    const formatAge = `${duration.years}歲${duration.months}個月`;

    return {
      date: formatBirthday,
      age: formatAge,
      format: `${formatBirthday} (${formatAge})`,
    };
  }

  get isBirthControl() {
    const isControl = Boolean(Number(this.#data.isBirthControl));
    const isControlNull = isNull(this.#data.isBirthControl);
    return {
      value: isControl,
      format: isControlNull ? '未登記' : isControl ? '已節育' : '未節育',
    };
  }

  get weight() {
    return (this.#data.petWeight ?? '未登記') + ' Kg';
  }

  get description() {
    return this.#data.description || '--';
  }

  get petGender() {
    return {
      id: this.#data.genderId,
      name: this.#data.genderId ? this.#data.gender.genderName : '未登記',
    };
  }

  get petType() {
    return {
      id: this.#data.petTypeId,
      name: this.#data.petTypeId ? this.#data.petType.typeName : '未登記',
    };
  }

  get petVariety() {
    return {
      id: this.#data.petVarietyId,
      name: this.#data.petVarietyId ? this.#data.petVariety.varietyName : '未登記',
      description: this.#data.petVarietyId ? this.#data.petVariety.description : null,
      petTypeId: this.#data.petVarietyId ? this.#data.petVariety.petTypeId : null,
    };
  }

  get petBlood() {
    return {
      id: this.#data.petBloodId,
      name: this.#data.petBloodId ? this.#data.petBlood.bloodName : '未登記',
    };
  }

  get member() {
    return {
      id: this.#data.memberId,
      memberName: this.#data.member.memberName,
      memberCode: this.#data.member.memberCode,
      phone: this.#data.member.phone,
    };
  }

  get isKilled() {
    const isDead = Boolean(this.#data.killUserId);

    return {
      value: isDead,
      format: isDead ? '已註冊死亡' : '',
      killTime: isDead ? format(new Date(this.#data.killTime), 'yyyy-MM-dd HH:mm:ss') : null,
      killUserId: isDead ? this.#data.killUserId : null,
      killUserName: isDead ? this.#data.killUserName : null,
    };
  }

  get information() {
    return {
      createTime: this.#data.createTime,
    };
  }

  get isDeleted() {
    return Boolean(this.#data.deleted);
  }
}
