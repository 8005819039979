import Link from 'next/link';
import { useRouter } from 'next/router';
import MenuButton from '../MenuButton';

/**
 * @param {Object} link
 * @param {string} link.to
 * @param {string} link.name
 * @param {string} link.iconName
 * @param {string} link.displayName
 * @param {?bool} link.external
 */
export default function MenuLink({ link }) {
  const router = useRouter();

  if (link.external) {
    return (
      <a href={link.to} target="__blank" style={{ color: 'inherit', textDecoration: 'inherit' }}>
        <MenuButton iconName={link.iconName} displayName={link.displayName} />
      </a>
    );
  }
  return (
    <Link href={link.to} style={{ color: 'inherit', textDecoration: 'none' }}>
      <MenuButton iconName={link.iconName} displayName={link.displayName} active={router.route === link.to} />
    </Link>
  );
}
