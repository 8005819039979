import { useRef } from 'react';
import { Pagination } from '@mui/material';

export default function Paginator({ givenDataTotalPage, onChange }) {
  const totalPage = useRef(1);

  totalPage.current = givenDataTotalPage ? givenDataTotalPage : totalPage.current;

  return (
    <Pagination
      size="small"
      count={totalPage.current}
      onChange={onChange}
      disabled={!givenDataTotalPage}
      sx={{ minWidth: 'max-content' }}
    />
  );
}
