import useSWR from 'swr';

import URLs from 'data/urls';
import { fetcher } from 'service/fetcher/index';
// import queryConverter from 'service/helper/queryConverter';

export function useConfigService() {
  const serviceURL = URLs.data;

  return {
    useAllConfig({ query, swrOpt, isAble = true }: APIRequest.APIArgus<URLSearchParams> = {}) {
      // const queryStr = queryConverter(query);
      const fetchURL = isAble ? `${serviceURL}/config` : null;
      return useSWR<API.Config>(fetchURL, fetcher, swrOpt);
    },
  };
}
