export default class Navigator {
  static toMedicalRecord(petId: string, mrId?: string): string {
    let path = `/medical-record?petId=${petId}`;
    if (mrId) {
      path += `&mrId=${mrId}`;
    }
    return path;
  }
  static toPet(petId: string): string {
    return `/pet/${petId}`;
  }
  static toMember(memberId: string): string {
    return `/member/${memberId}`;
  }
}
