/**
 * @deprecated - please use nav path instead
 */
const path = {
  // toHome: () => "/",

  // toMemberList: () => "/member/list",
  toOneMember: memberId => `/member/${memberId}`,
  toOneMemberEdit: memberId => `/member/${memberId}?edit=true`,

  //new
  toPetList: () => "/pet/list",
  toOnePet: petId => `/pet/${petId}`,
  toOnePetEdit: petId => `/pet/${petId}/edit`,
  toOnePetMedicalRecord: petId => `/medical-record?petId=${petId}`,
  //old, should be removed
  // toOldOnePet: (petId, memberId) => `/member/${memberId}/${petId}`,
  // toOldOnePetEdit: (petId, memberId) => `/member/${memberId}/${petId}/edit`,

  //old
  // toOldMedicalRecord: () => "/medicalRecord",
  // toOldMedicalRecordOfOnePet: petId => `/medical-record?petId=${petId}`,
  // toOldOneMedicalRecord: (petId, medicalRecordId) =>
  //   `/medical-record?petId=${petId}&mrId=${medicalRecordId}`,
  // toOldOneMedicalRecordEdit: (petId, medicalRecordId) =>
  //   `/medicalRecord/${petId}/${medicalRecordId}/edit`,

  toPharmacy: () => "/pharmacy",
  toPharmacyList: () => "/pharmacy/list",
  toPharmacyOnePack: (date, packId) => `/pharmacy?date=${date}&packId=${packId}`,

  toDocument: (memberId, petId) => `/document?memberId=${memberId}&petId=${petId}`,
};

export default path;
