import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Chip,
  ListItemIcon,
  Skeleton,
} from '@mui/material';
import { useState, createContext, useContext, useEffect } from 'react';
import _ from 'lodash';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useInfiniteUser } from 'service';
import { usePetDialogContext } from 'components/Pet/PetFormDialog/Context';

import Icon from 'components/Icon';
import useDebounce from 'libs/hooks/useDebounce';

const LIMIT = 20;

const DialogContext = createContext();

function ItemRenderer({ data, index, style }) {
  const { isLoading, onChoose } = useContext(DialogContext);
  const item = data[Math.floor(index / LIMIT)]?.list[index % LIMIT];

  if (!item && isLoading) {
    return (
      <Stack width="100%" height={48} direction="row" spacing={1} style={style} sx={{ p: 1 }}>
        <Skeleton variant="rounded" width="80%" height={40} />
        <Skeleton variant="rounded" width="20%" height={40} />
      </Stack>
    );
  }

  if (!item && !isLoading) return null;

  return (
    <ListItem
      style={style}
      key={index}
      component="div"
      disablePadding
      sx={{ borderBottom: '1px solid #00000010', '&:last-of-type': { borderBottom: 'unset' } }}
      onClick={() => onChoose(item.id)}
    >
      <ListItemButton sx={{ alignItems: 'center', pr: 1 }}>
        <ListItemText primary={item.memberName} sx={{ flex: 2 }} />
        <ListItemText primary={item.phone} sx={{ flex: 2 }} />
        <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ flex: 1.6 }}>
          {_.size(item.pets) > 0 ? (
            <>
              <Chip label={item.pets[0].petName} size="small" />
              {item.pets[1] && <Chip label={item.pets[1].petName} size="small" />}
              {_.size(item.pets) > 2 && <Chip label={`+${_.size(item.pets) - 2}`} size="small" variant="outlined" />}
            </>
          ) : (
            <div />
          )}
        </Stack>
        <ListItemIcon sx={{ flex: 0.4, minWidth: 'auto', '& > *': { ml: 'auto' } }}>
          <Icon iconName="chevRight" />
        </ListItemIcon>
      </ListItemButton>
    </ListItem>
  );
}

export default function Member({ onChoose }) {
  const { open, onClose, memberId } = usePetDialogContext();
  const [searchQuery, setSearchQuery] = useState({});
  const [delaySearchQuery, setDelaySearchQuery] = useState({});
  const { data, size, setSize, isLoading, currentTotal } = useInfiniteUser({
    limit: LIMIT,
    ...delaySearchQuery,
  });

  const handleLoadMore = (s, e) => {
    if (!isLoading) {
      setSize(size + 1);
    }
    return Promise.resolve();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setSearchQuery(p => ({ ...p, [name]: value }));
  };

  useDebounce(() => setDelaySearchQuery(searchQuery), 500, [searchQuery]);
  useEffect(
    () => () => {
      setSearchQuery({});
      setDelaySearchQuery({});
    },
    [open],
  );

  return (
    <DialogContext.Provider value={{ isLoading, onChoose }}>
      <Dialog open={Boolean(open && !memberId)} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>新增寵物 - 選擇會員</DialogTitle>
        <DialogContent>
          <DialogContentText>請先選擇會員</DialogContentText>
          <Stack direction="row" spacing={1} my={2}>
            <TextField label="會員名稱" name="name" onChange={handleChange} />
            <TextField label="會員電話" name="phone" onChange={handleChange} />
            <TextField label="寵物名字" name="petName" onChange={handleChange} />
          </Stack>
          <Stack sx={{ width: '100%', height: '500px' }}>
            {data && (
              <AutoSizer>
                {({ width, height }) => (
                  <InfiniteLoader
                    isItemLoaded={i => Boolean(data[Math.floor(i / LIMIT)]?.list[i % LIMIT])}
                    itemCount={data.at(-1).totalCount}
                    loadMoreItems={handleLoadMore}
                  >
                    {({ onItemsRendered, ref }) => (
                      <FixedSizeList
                        onItemsRendered={onItemsRendered}
                        ref={ref}
                        height={height}
                        width={width}
                        itemData={data}
                        itemCount={currentTotal + 1}
                        itemSize={48}
                        itemKey={(index, data) => data[Math.floor(index / LIMIT)]?.list[index % LIMIT]?.id || `${index}_loading`}
                      >
                        {ItemRenderer}
                      </FixedSizeList>
                    )}
                  </InfiniteLoader>
                )}
              </AutoSizer>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </DialogContext.Provider>
  );
}
