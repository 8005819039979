import { TextField } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import CustomActionBar from './CustomActionBar';
import { getInputProp } from 'libs/input';

/**
 * this component is supported by LocalizationProvider
 */
export default function BirthDatePicker({ size, fullWidth, inputPropName, ...props }) {
  return (
    <MobileDatePicker
      {...props}
      label="生日"
      inputFormat="yyyy-MM-dd"
      openTo="year"
      views={['year', 'month', 'day']}
      maxDate={new Date()}
      toolbarFormat="yyyy-MM-dd"
      renderInput={p => (
        <TextField {...p} {...getInputProp(inputPropName)} fullWidth={fullWidth} size={size} />
      )}
      components={{
        ActionBar: CustomActionBar,
      }}
    />
  );
}
