import { forwardRef, cloneElement } from "react";

import Assignment from "@mui/icons-material/Assignment";
import Satellite from "@mui/icons-material/Satellite";
import Description from "@mui/icons-material/Description";
import Event from "@mui/icons-material/Event";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import Mail from "@mui/icons-material/Mail";
import Info from "@mui/icons-material/Info";
import Edit from "@mui/icons-material/Edit";
import MeetingRoom from "@mui/icons-material/MeetingRoom";
import LocalPhone from "@mui/icons-material/LocalPhone";
import Pets from "@mui/icons-material/Pets";
import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import Cancel from "@mui/icons-material/Cancel";
import Person from "@mui/icons-material/Person";
import Add from "@mui/icons-material/Add";
import Sync from "@mui/icons-material/Sync";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import NavigateNext from "@mui/icons-material/NavigateNext";
import Search from "@mui/icons-material/Search";
import AddCircle from "@mui/icons-material/AddCircle";
import DeleteForever from "@mui/icons-material/DeleteForever";
import Refresh from "@mui/icons-material/Refresh";
import Save from "@mui/icons-material/Save";
import Clear from "@mui/icons-material/Clear";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Check from "@mui/icons-material/Check";
import Print from "@mui/icons-material/Print";
import ContentCopy from "@mui/icons-material/ContentCopy";
import Receipt from "@mui/icons-material/Receipt";
import Menu from "@mui/icons-material/Menu";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Home from "@mui/icons-material/Home";
import Biotech from "@mui/icons-material/Biotech";
import Medication from "@mui/icons-material/Medication";
import BarChart from "@mui/icons-material/BarChart";
import OpenInNew from "@mui/icons-material/OpenInNew";
import People from "@mui/icons-material/People";
import MoreVert from "@mui/icons-material/MoreVert";
import Schedule from "@mui/icons-material/Schedule";
import Close from "@mui/icons-material/Close";
import Logout from "@mui/icons-material/Logout";
import Money from "@mui/icons-material/AttachMoney";
import CheckCircle from "@mui/icons-material/CheckCircle";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import Equalizer from "@mui/icons-material/Equalizer";
import List from "@mui/icons-material/List";
import PermMedia from "@mui/icons-material/PermMedia";
import UploadFile from "@mui/icons-material/UploadFile";
import QrCode from "@mui/icons-material/QrCode";
import Computer from "@mui/icons-material/Computer";
import Camera from "@mui/icons-material/CameraAlt";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import Favorite from "@mui/icons-material/Favorite";
import Warning from "@mui/icons-material/Warning";
import Paid from "@mui/icons-material/Paid";
import CurrencyExchange from "@mui/icons-material/CurrencyExchange";
import ReplayCircleFilled from "@mui/icons-material/ReplayCircleFilled";
import HowToReg from "@mui/icons-material/HowToReg";
import QuestionMark from "@mui/icons-material/QuestionMark";
import Settings from "@mui/icons-material/Settings";
import LocalHospital from "@mui/icons-material/LocalHospital";
import Face from "@mui/icons-material/Face";
import ContentPaste from "@mui/icons-material/ContentPaste";
import ArrowForward from "@mui/icons-material/ArrowForward";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import SpaceDashBoard from "./SpaceDashboard";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

const renderIcon = {
  heart: <Favorite />,
  warning: <Warning />,
  menu: <Menu />,
  //common action and nav
  medicalRecord: <Assignment color="info" />,
  pacs: <Satellite color="warning" />,
  document: <Description color="secondary" />,
  reservation: <Event color="success" />,
  calendar: <CalendarMonth color="success" />,
  sms: <Mail color="error" />,
  smsIcon: <PermPhoneMsgIcon />,
  smsModule: <ForwardToInboxIcon />,
  info: <Info color="primary" />,
  edit: <Edit color="error" />,
  add: <Add />,
  addCircle: <AddCircle />,
  sync: <Sync />,
  search: <Search />,
  delete: <DeleteForever color="error" />,
  refresh: <Refresh />,
  save: <Save />,
  clear: <Clear />,
  checkCircle: <CheckCircle />,
  check: <Check />,
  done: <Check />,
  print: <Print />,
  copy: <ContentCopy />,
  receipt: <Receipt />,
  openInNew: <OpenInNew />,
  moreVert: <MoreVert />,
  moreHoriz: <MoreHoriz />,
  close: <Close />,
  clock: <Schedule />,
  exit: <Logout />,
  money: <Money />,
  pdf: <PictureAsPdf />,
  //direction
  //nav / chevron
  chevUp: <ExpandLess />,
  chevDown: <ExpandMore />,
  chevLeft: <NavigateBefore />,
  chevRight: <NavigateNext />,
  //arrow
  arrowDropDown: <ArrowDropDown />,
  arrowLeft: <ArrowBack />,
  arrowRight: <ArrowForward />,
  //reservation
  walkIn: <MeetingRoom />,
  phone: <LocalPhone />,
  checkBox: <CheckBox />,
  uncheckBox: <CheckBoxOutlineBlank />,
  cancel: <Cancel />,
  //pet
  pet: <Pets />,
  //member
  member: <Person />,
  doctor: <Person />,

  //menu
  home: <Home />,
  testReport: <Biotech />,
  pharmacy: <Medication />,
  barChart: <BarChart />,
  people: <People />,
  graph: <Equalizer />,
  list: <List />,

  media: <PermMedia />,
  upload: <UploadFile />,
  qrCode: <QrCode />,
  computer: <Computer />,
  camera: <Camera />,

  paid: <Paid />,
  exchange: <CurrencyExchange />,
  replay: <ReplayCircleFilled />,
  "person-check": <HowToReg />,

  question: <QuestionMark />,
  settings: <Settings />,
  hospital: <LocalHospital />,
  spaceDashBoard: <SpaceDashBoard />,
  face: <Face />,
  board: <ContentPaste />,

  circle: <RadioButtonUnchecked />,

  tempSales: <RequestQuoteIcon />,
} as const;

interface IconProps {
  iconName: keyof typeof renderIcon;
  color?: string;
  [x: string]: any;
}

const Icon = forwardRef<HTMLOrSVGElement, IconProps>(({ iconName, color, ...props }, ref) => {
  return cloneElement(renderIcon[iconName], {
    ...(color && { color }),
    ...props,
    ref,
  });
});
Icon.displayName = "Icon";

export default Icon;
