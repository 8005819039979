import { useState, useRef } from 'react';

/**
 * @deprecated - try useDebounce
 */
export default function useDelayValue(defaultValue = null, delay = 500) {
  const timeoutRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const [delayValue, setDelayValue] = useState(defaultValue);

  const handleValueChange = value => {
    clearTimeout(timeoutRef.current);

    setValue(value);
    setIsLoading(true);

    timeoutRef.current = setTimeout(() => {
      setDelayValue(value);
      setIsLoading(false);
    }, delay);
  };

  return [value, delayValue, handleValueChange, isLoading];
}
