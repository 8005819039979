/*
  [ ] RWD
*/
import { useState, forwardRef, useEffect } from 'react';
import { useRouter } from 'next/router';
import { styled } from '@mui/system';
import {
  Stack,
  Dialog,
  Slide,
  IconButton,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PetsIcon from '@mui/icons-material/Pets';
import PersonIcon from '@mui/icons-material/Person';

import { PetSearchField, MemberSearchField } from './SearchField';
import QuickFormDialog from 'features/QuickFormDialog';
import MemberFormDialog from 'components/Member/MemberFormDialog';
import PetFormDialog from 'components/Pet/PetFormDialog';

const StyledDialog = styled(Dialog)({
  height: '95%',
  top: 'unset',
  '& .MuiDialog-paper': {
    backgroundColor: '#EFEFEF',
  },
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    gap: 8,
  },
  '& .MuiAccordionDetails-root': {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
});

const ActionSection = styled(Stack)({
  flexDirection: 'row',
  gap: 16,
  margin: '8px 0',
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let init = true;

export default function SearchDialog() {
  const router = useRouter();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (init) {
      init = false;
      return;
    }

    handleClose();

    // eslint-disable-next-line
  }, [router.asPath]);

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <SearchIcon sx={{ fontSize: '32px' }} />
      </IconButton>
      <StyledDialog open={open} onClose={handleClose} TransitionComponent={Transition} maxWidth="lg" fullWidth>
        <DialogTitle>搜尋小視窗</DialogTitle>
        <DialogContent>
          <ActionSection>
            <QuickFormDialog />
            <MemberFormDialog />
            <PetFormDialog />
          </ActionSection>
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <PetsIcon />
              <Typography variant="h6">找寵物</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PetSearchField />
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <PersonIcon />
              <Typography variant="h6">找會員</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MemberSearchField />
            </AccordionDetails>
          </Accordion>
        </DialogContent>
      </StyledDialog>
    </div>
  );
}
