import { TextField, InputAdornment, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { SelectPetGender, SelectPetType, SelectPetVariety, SelectPetBlood } from "components/Input/AutoComplete";
import { getInputProp } from "libs/input";

import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CustomActionBar from "components/Input/DatePicker/BirthDatePicker/CustomActionBar";

/**
 * this component is supported by LocalizationProvider
 */
function BirthDatePicker({ size, fullWidth, inputPropName, ...props }) {
  return (
    <MobileDatePicker
      {...props}
      label="生日"
      inputFormat="yyyy-MM-dd"
      openTo="year"
      views={["year", "month", "day"]}
      maxDate={new Date()}
      toolbarFormat="yyyy-MM-dd"
      renderInput={p => <TextField {...p} {...getInputProp(inputPropName)} fullWidth={fullWidth} size={size} />}
      components={{
        ActionBar: CustomActionBar,
      }}
    />
  );
}

export default function Form({ selectionData, onChangeSelectionData, petData }) {
  return (
    <>
      <TextField label="名稱" inputProps={getInputProp("petName")} required defaultValue={petData?.petName} />
      <SelectPetGender
        value={selectionData.gender || null}
        onChange={(e, v) => onChangeSelectionData("gender", v)}
        renderInput={p => <TextField {...p} label="性別" {...getInputProp("genderId")} required />}
      />

      <MobileDatePicker
        value={selectionData.birthday}
        onChange={v => onChangeSelectionData("birthday", v)}
        label="生日"
        inputFormat="yyyy-MM-dd"
        openTo="year"
        views={["year", "month", "day"]}
        maxDate={new Date()}
        toolbarFormat="yyyy-MM-dd"
        renderInput={p => <TextField {...p} {...getInputProp("birthday")} fullWidth required />}
        components={{
          ActionBar: CustomActionBar,
        }}
      />
      <SelectPetType
        value={selectionData.petType || null}
        onChange={(e, v) => onChangeSelectionData("petType", v)}
        renderInput={p => <TextField {...p} label="類別" {...getInputProp("petTypeId")} required />}
      />
      <SelectPetVariety
        value={selectionData.petVariety || null}
        onChange={(e, v) => onChangeSelectionData("petVariety", v)}
        petTypeQuery={selectionData.petType.id}
        renderInput={p => <TextField {...p} label="品種" {...getInputProp("petVarietyId")} />}
      />

      <TextField label="晶片" inputProps={getInputProp("icCard")} defaultValue={petData?.icCard} />
      <TextField
        label="體重"
        inputProps={getInputProp("petWeight")}
        InputProps={{
          endAdornment: <InputAdornment position="end">kg</InputAdornment>,
        }}
        defaultValue={petData?.petWeight || "0"}
      />
      <SelectPetBlood
        value={selectionData.petBlood || null}
        onChange={(e, v) => onChangeSelectionData("petBlood", v)}
        renderInput={p => <TextField {...p} label="血型" {...getInputProp("petBloodId")} />}
      />

      <FormControl required>
        <FormLabel>節育</FormLabel>
        <RadioGroup row {...getInputProp("isBirthControl")} defaultValue={petData?.isBirthControl}>
          <FormControlLabel value="0" control={<Radio />} label="未節育" />
          <FormControlLabel value="1" control={<Radio />} label="已節育" />
        </RadioGroup>
      </FormControl>
      <TextField label="備註" multiline minRows={3} inputProps={getInputProp("description")} defaultValue={petData?.description} />
    </>
  );
}
