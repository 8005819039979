import { Chip, TextField, DialogContentText } from '@mui/material';
import { useMemberFormContext } from '../Context';
import Container from './Container';
import { getInputProp } from 'libs/input';

export default function Description() {
  const { memberData } = useMemberFormContext();
  return (
    <Container>
      <Chip label="備註資訊" color="primary" sx={{ alignSelf: 'flex-start' }} />
      <DialogContentText>提供三種欄位，可以依照作業流程與需求使用</DialogContentText>
      <TextField
        label="注意事項"
        multiline
        minRows={4}
        inputProps={getInputProp('note')}
        defaultValue={memberData?.note}
      />
      <TextField
        label="備註"
        multiline
        minRows={4}
        inputProps={getInputProp('description')}
        defaultValue={memberData?.description}
      />
      <TextField
        label="說明"
        multiline
        minRows={4}
        inputProps={getInputProp('description2')}
        defaultValue={memberData?.description2}
      />
    </Container>
  );
}
