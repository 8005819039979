import Link from 'next/link';
import { cloneElement } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Icon from 'components/Icon/index';
import { toOnePet } from 'libs/navPath';

export default function PetInfoActionButton({ petId, button }) {
  return (
    <Link href={toOnePet(petId)}>
      {button ? (
        cloneElement(button)
      ) : (
        <Tooltip title="更多資訊">
          <IconButton size="small">
            <Icon iconName="pet" color="success" />
          </IconButton>
        </Tooltip>
      )}
    </Link>
  );
}
