import { TableRow, TableCell } from '@mui/material';
import PetInfoDialog from 'components/Pet/PetInfoDialog';
import PetAvatarChip from 'components/Pet/PetAvatarChip';
import TooltipIconButton from 'components/UI/Button/TooltipIconButton';
import NewTag from 'components/UI/NewTag';
import { EditActionButton, InfoActionButton, ReservationActionButton } from 'features/ActionButton';
import SmsActionButton from 'features/ActionButton/Sms';

const MemberListItem = ({ member }) => {
  return (
    <>
      <TableRow
        key={member.memberCode}
        hover
        sx={{ '& .MuiTableCell-root': { borderBottom: 'none' } }}
      >
        <TableCell>{member.memberCode}</TableCell>
        <TableCell>
          {member.memberName}
          <NewTag time={member.createTime} />
        </TableCell>
        <TableCell>{member.genderId ? member?.gender?.genderName : '--'}</TableCell>
        <TableCell>{member.phone}</TableCell>
        <TableCell>
          {(`${member.localAreaCode ?? ''}${member.localPhone ?? ''}${member.localExt ? '#' + member.localExt : ''
            }`.trim() || '--') +
            ' / ' +
            (` ${member.companyAreaCode ?? ''}${member.companyPhone ?? ''}${member.companyExt ? '#' + member.companyExt : ''
              }`.trim() || '--')}
        </TableCell>
        <TableCell>
          {`${member.contactAddressCounty ?? ''}${member.contactAddressCity ?? ''}${member.contactAddressStreet ?? ''
            }` || '--'}
        </TableCell>

        <TableCell align='center'>
          <ReservationActionButton preMemberId={member.id} />
          <SmsActionButton memberId={member.id} phone={member.phone} />
          <InfoActionButton memberId={member.id} />
          <EditActionButton memberId={member.id} />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={7}>
          {member.pets.map(pet => (
            <PetInfoDialog
              key={pet.id}
              petId={pet.id}
              ButtonComponent={props => (
                <PetAvatarChip label={pet.petName} isDeath={pet.killUserId} {...props} />
              )}
            />
          ))}
        </TableCell>
      </TableRow>
    </>
  );
};

export default MemberListItem;
