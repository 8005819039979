import TextField from '@mui/material/TextField';
import { MobileTimePicker } from '@mui/x-date-pickers';

export default function TimePicker({ name, required, ...props }) {
  return (
    <MobileTimePicker
      label="時間"
      renderInput={params => <TextField {...params} required={required} name={name} />}
      ampm={false}
      {...props}
    />
  );
}
