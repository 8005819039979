import { Stack, Chip, Typography, IconButton, Tooltip, Divider } from '@mui/material';
import Icon from 'components/Icon';
import Brand from 'components/Brand/Brand';
import ConfirmDialog from 'components/ConfirmDialog/index';

import { QueueCard, QueueCardHead, QueueCardFoot } from './style';

export default function QueueCardItem({ item, onInit }) {
  return (
    <QueueCard key={`queueDrawer_${item.id}`}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ mt: 1 }}>
        <Chip label={item.startTime} color="primary" size="small" />
        <Typography variant="body2">{item.startDate}</Typography>
      </Stack>

      <QueueCardHead>
        <Stack flexGrow="1">
          <Typography fontWeight="bolder">{item.pet.petName}</Typography>
          <Typography variant="body2">
            {item.pet.petCode} | {item.pet.originPetCode || '--'}
          </Typography>
          <Typography variant="body2">飼主：{item.member.memberName}</Typography>
        </Stack>
        <ConfirmDialog
          title="確認開始看診？"
          onConfirm={() =>
            onInit({
              petIdentityId: item.petIdentityId,
              memberId: item.memberId,
              memberReserveId: item.id,
            })
          }
        >
          <Tooltip title="開始看診">
            <IconButton>
              <Icon iconName="add" />
            </IconButton>
          </Tooltip>
        </ConfirmDialog>
      </QueueCardHead>
      <Divider flexItem />
      <QueueCardFoot>
        <Brand>備註</Brand>
        <Typography variant="body2">{item.description}</Typography>
      </QueueCardFoot>
    </QueueCard>
  );
}
