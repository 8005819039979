import { Alert, AlertTitle, Typography } from '@mui/material';

export default function PreDataAlert({ petName, memberName }) {
  return (
    <Alert>
      <AlertTitle>已帶入相關資訊</AlertTitle>
      {petName && <Typography variant="body2">寵物：{petName}</Typography>}
      {memberName && <Typography variant="body2">會員：{memberName}</Typography>}
    </Alert>
  );
}
