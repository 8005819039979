import Link from 'next/link';
import { cloneElement } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import Icon from 'components/Icon';
import { toOneMember, toOnePet } from 'libs/navPath';

export default function InfoActionButton({ petId, memberId, button }) {
  const path = petId ? toOnePet(petId) : memberId ? toOneMember(memberId) : '';
  return (
    <Link href={path}>
      {button ? (
        cloneElement(button)
      ) : (
        <Tooltip title="更多資訊">
          <IconButton size="small">
            <Icon iconName="info" color="primary" />
          </IconButton>
        </Tooltip>
      )}
    </Link>
  );
}
