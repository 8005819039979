import { Chip, TextField, Stack, InputAdornment } from '@mui/material';
import { useMemberFormContext } from '../Context';
import Container from './Container';
import Brand from 'components/Brand/Brand';
import { getInputProp } from 'libs/input';
import { AddressSection } from './Address';

export default function Company() {
  const { memberData } = useMemberFormContext();
  return (
    <Container>
      <Chip label="公司資訊" color="primary" sx={{ alignSelf: 'flex-start' }} />
      <TextField label="名稱" inputProps={getInputProp('companyName')} defaultValue={memberData?.companyName} />
      <TextField label="統一編號" inputProps={getInputProp('companyUniformNumber')} defaultValue={memberData?.companyUniformNumber} />
      <Brand color="secondary">公司電話</Brand>
      <Stack direction="row" gap={1}>
        <TextField label="區碼" inputProps={getInputProp('companyAreaCode')} defaultValue={memberData?.companyAreaCode} />
        <TextField label="號碼" inputProps={getInputProp('companyPhone')} defaultValue={memberData?.companyPhone} />
        <TextField
          label="分機"
          inputProps={getInputProp('companyExt')}
          defaultValue={memberData?.companyExt}
          InputProps={{
            startAdornment: <InputAdornment position="start">#</InputAdornment>,
          }}
        />
      </Stack>
      <Brand color="secondary">公司地址</Brand>
      <AddressSection renderName="company" defaultValue={memberData?.company} />
    </Container>
  );
}
