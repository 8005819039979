import React, { forwardRef } from "react";

import AddIcon from "@mui/icons-material/Add";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import InfoIcon from "@mui/icons-material/Info";
import MailIcon from "@mui/icons-material/Mail";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import ListIcon from "@mui/icons-material/List";
import ImageIcon from "@mui/icons-material/Image";
import SatelliteIcon from "@mui/icons-material/Satellite";
import ArticleIcon from "@mui/icons-material/Article";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FilterListIcon from "@mui/icons-material/FilterList";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckIcon from "@mui/icons-material/Check";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CancelIcon from "@mui/icons-material/Cancel";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import SaveIcon from "@mui/icons-material/Save";
import TodayIcon from "@mui/icons-material/Today";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import LogoutIcon from "@mui/icons-material/Logout";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PrintIcon from "@mui/icons-material/Print";
import BarChartIcon from "@mui/icons-material/BarChart";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DownloadIcon from "@mui/icons-material/Download";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PersonIcon from "@mui/icons-material/Person";
import PetsIcon from "@mui/icons-material/Pets";
import MedicationIcon from "@mui/icons-material/Medication";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Check from "@mui/icons-material/Check";
import SyncIcon from "@mui/icons-material/Sync";

const renderIcon = {
  schedule: <EventNoteIcon color="success" />,
  assignment: <AssignmentIcon color="info" />,
  edit: <EditIcon color="error" />,
  search: <SearchIcon />,
  info: <InfoIcon color="primary" />,
  mail: <MailIcon color="error" />,
  card: <ViewAgendaIcon />,
  list: <ListIcon />,
  image: <ImageIcon color="warning" />,
  satellite: <SatelliteIcon color="warning" />,
  document: <ArticleIcon color="secondary" />,
  left: <ChevronLeftIcon />,
  right: <ChevronRightIcon />,
  up: <KeyboardArrowUpIcon />,
  down: <ExpandMoreIcon />,
  filter: <FilterListIcon />,
  refresh: <RefreshIcon />,
  add: <AddIcon />,
  check: <CheckIcon />,
  unpaid: <CurrencyExchangeIcon />,
  checkbox: <CheckBoxIcon />,
  unCheckbox: <CheckBoxOutlineBlankIcon />,
  cancel: <CancelIcon />,
  close: <CloseIcon />,
  openInNew: <OpenInNewIcon />,
  expand: <OpenInFullIcon />,
  save: <SaveIcon />,
  today: <TodayIcon />,
  eye: <VisibilityIcon />,
  copy: <CopyAllIcon />,
  delete: <DeleteForeverIcon />,
  file: <InsertDriveFileIcon />,
  upload: <UploadFileIcon />,
  out: <LogoutIcon />,
  money: <AttachMoneyIcon />,
  print: <PrintIcon />,
  barChart: <BarChartIcon />,
  list: <FormatListBulletedIcon />,
  circleCheck: <CheckCircleIcon />,
  download: <DownloadIcon />,
  pdf: <PictureAsPdfIcon />,
  door: <MeetingRoomIcon />,
  phone: <LocalPhoneIcon />,
  person: <PersonIcon />,
  pet: <PetsIcon />,
  medicine: <MedicationIcon />,
  arrowDropDown: <ArrowDropDownIcon />,
  check: <Check />,
  sync: <SyncIcon />,
};

/**
 * @deprecated - please use new Icon component Icon/index.js
 */
const Icon = forwardRef(({ type, ...props }, ref) => {
  return React.cloneElement(renderIcon[type], { ...props, ref });
});
Icon.displayName = "Icon";

export default Icon;
