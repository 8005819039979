import { Children, cloneElement, useCallback } from "react";
import { Stack } from "@mui/material";

/**
 * if you are using `MUI <AutoComplete/>`
 * you might want to use controlled method to get the actual value
 * e.g.`{ id: "XXX", displayName: "VVV"}`
 * (since the value you want might be `id` but you have to show the `displayName` for user)
 * @param {*} [any] - any thing that support `MUI <Stack/>` and also HTML tag `<form/>`
 * @param {boolean} required - will automatically add `required` prop to all child (but not all, e.g. `MUI <AutoComplete/>` is a exception)
 * @param {boolean} disabled - will try to early return when submit
 *
 */
export default function Form({ children, required, disabled, ...props }) {
  //this will help to package a formData to previous cb, for convince
  const handleSubmit = useCallback(
    evt => {
      evt.preventDefault();

      if (disabled) return;

      const formData = new FormData(evt.target);
      const data = {};
      for (const [k, v] of formData) {
        data[k] = v;
      }
      return props.onSubmit(evt, data);
    },
    [disabled, props],
  );

  return (
    <Stack as="form" mt={1.5} gap={1.5} {...props} onSubmit={handleSubmit}>
      {Children.map(children, child => cloneElement(child, { required, ...child.props }))}
    </Stack>
  );
}
