import { cloneElement, useEffect, useState } from "react";
import { Button } from "@mui/material";
import useToggle from "libs/hooks/useToggle";
import Icon from "components/Icon";
import { PetDialogProvider } from "./Context";
import { Member, Pet } from "./Steps";

export default function PetFormDialog({ button, defaultMemberId, defaultPetId }) {
  const [open, toggle] = useToggle();
  const [memberId, setMemberId] = useState(defaultMemberId || null);

  const isEditing = Boolean(defaultPetId);

  useEffect(() => () => setMemberId(defaultMemberId || null), [defaultMemberId, open]);

  return (
    <>
      {button ? (
        cloneElement(button, { onClick: toggle })
      ) : (
        <Button color="secondary" startIcon={<Icon iconName="add" />} variant="contained" disableElevation onClick={toggle}>
          {isEditing ? "編輯寵物" : "新增寵物"}
        </Button>
      )}
      <PetDialogProvider open={open} onClose={toggle.toFalse} memberId={memberId}>
        {/* step 1 - choose member | show when open && !memberId && !petId */}
        {!defaultMemberId && <Member onChoose={memberId => setMemberId(memberId)} />}
        {/* step 2 - create pet    | show when open && (memberId || petId) */}
        <Pet petId={defaultPetId} />
      </PetDialogProvider>
    </>
  );
}
