import useSWR from "swr";

import URLs from "data/urls";
import { useToken } from "components/Provider/AuthProvider";

import { postFetch } from "service/helper/fetcher";

type SmsParamsType = {
  memberId?: string;
  sendPhone?: string;
  page: string;
  limit: string;
  sort: string;
}

const createSmsPayload = (params: SmsParamsType) => ({
  httpRouter: "/sms",
  httpPayload: {},
  queryParams: params
});

export function useSmsService() {
  const serviceURL = URLs.sms;
  const token = useToken();

  return {
    useAllSms({ memberId = "", sendPhone = "", page = "1", limit = "10", sort = "create_time+desc", swrOpt = {}, isAble = true } = {}) {
      const params = {
        memberId: memberId || "",
        sendPhone: sendPhone || "",
        page,
        limit,
        sort
      };

      return useSWR<APIResponse.Pagination<API.Sms>>(
        isAble ? [`${serviceURL}/get`] : null,
        (url: string) => postFetch(url, {
          token,
          data: createSmsPayload(params)
        }),
        {
          revalidateOnFocus: false,
          ...swrOpt
        }
      );
    },
  };
}