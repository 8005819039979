import { SvgIcon, SvgIconTypeMap } from "@mui/material";

export default function SpaceDashBoardIcon(props: SvgIconTypeMap["props"]) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
        <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
          <path d="M5 21q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h6v18Zm8 0v-9h8v7q0 .825-.587 1.413Q19.825 21 19 21Zm0-11V3h6q.825 0 1.413.587Q21 4.175 21 5v5Z" />
        </svg>
      </svg>
    </SvgIcon>
  );
}
