import { Tooltip, Typography } from '@mui/material';
import Brand from './Brand';

/**
 * @deprecated
 */
export default function DeathBrand({ data, size }) {
  if (!data.value) return null;
  return (
    <Tooltip
      title={
        <>
          <Typography variant="body2">註冊時間：{data.killTime}</Typography>
          <Typography variant="body2">註冊使用者：{data.killUserName}</Typography>
        </>
      }
      enterTouchDelay={0}
    >
      <Brand color="error">{data.format}</Brand>
    </Tooltip>
  );
}
