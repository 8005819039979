import Link from "next/link";
import { cloneElement } from "react";
import { IconButton, Tooltip } from "@mui/material";
import Icon from "components/Icon/index";
import { toMedicalRecord } from "libs/navPath";

export default function MedicalRecordActionButton({ petId, mrId, button }) {
  return (
    <Link href={toMedicalRecord(petId, mrId)} style={{ color: "inherit", textDecoration: "none" }}>
      {button ? (
        cloneElement(button)
      ) : (
        <Tooltip title="病歷">
          <IconButton size="small" color="info">
            <Icon iconName="medicalRecord" />
          </IconButton>
        </Tooltip>
      )}
    </Link>
  );
}
