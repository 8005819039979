import useToggle from "libs/hooks/useToggle";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { cloneElement } from "react";
import { debounce } from "lodash";

export default function ConfirmDialog({ title, onConfirm, children, disabled }) {
  const [open, toggle] = useToggle();
  const [loading, toggleLoading] = useToggle();

  const handleClick = () => {
    toggleLoading();

    debounce(() => {
      onConfirm();
      toggle.toFalse();
      toggleLoading();
    }, 300)();
  };

  return (
    <>
      {cloneElement(children, { onClick: disabled ? null : toggle })}
      {!children && <Button onClick={disabled ? null : toggle}>{children}</Button>}
      <Dialog open={open} onClose={toggle.toFalse}>
        <DialogTitle>{title || "確認操作？"}</DialogTitle>
        <DialogActions>
          <LoadingButton loading={loading} onClick={handleClick}>
            確定
          </LoadingButton>
          <Button color="error" onClick={toggle.toFalse}>
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
