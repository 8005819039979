import { cloneElement } from "react";
import { IconButton, Tooltip } from "@mui/material";
import ReservingDialog from "features/Reservation/ReservingDialog";
import Icon from "components/Icon/index";

export default function ReservationActionButton({ button, ...props }) {
  return (
    <ReservingDialog
      {...props}
      button={
        button ? (
          cloneElement(button)
        ) : (
          <Tooltip title="預約">
            <IconButton size="small" color="success">
              <Icon iconName="reservation" />
            </IconButton>
          </Tooltip>
        )
      }
    />
  );
}
