import { toast } from 'react-toastify';
/**
 * @deprecated
 * @param {func} cb - a callback
 * @param {object} promiseParams - displayMsg when {pending, success, error}, see ref of react-toastify
 * @param {object} options - options of react-toastify
 * @returns {func}
 */
export default function notify(cb, basePromiseParams = {}) {
  return (...cbParams) =>
    (promiseParams = {}) =>
      toast.promise(cb(...cbParams), {
        pending: {
          render: () => '處理中',
          theme: 'dark',
          ...(basePromiseParams?.pending || {}),
          ...(promiseParams?.pending || {}),
        },
        success: {
          render: () => '操作成功',
          theme: 'colored',
          ...(basePromiseParams?.success || {}),
          ...(promiseParams?.success || {}),
        },
        error: {
          render: props => `操作失敗：${props?.data?.message || ''}`,
          theme: 'colored',
          ...(basePromiseParams?.error || {}),
          ...(promiseParams?.error || {}),
        },
      });
}
