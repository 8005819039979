/**
 * props are all depends on api format
 */
/**
 * @deprecated
 */
const inputProps = {
  /* member */
  phone: {
    name: 'phone',
    type: 'phone',
  },
  homePhone: {
    name: 'homePhone',
    type: 'phone',
  },
  memberName: {
    name: 'memberName',
    type: 'text',
  },
  ename: {
    name: 'ename',
    type: 'text',
  },
  genderId: {
    name: 'genderId',
    type: 'text',
  },
  birthdate: {
    name: 'birthdate',
    // type: "date", //using date-picker ignore it yay!
  },
  email: {
    name: 'email',
    type: 'email',
  },
  localAreaCode: {
    name: 'localAreaCode',
    type: 'phone',
  },
  localPhone: {
    name: 'localPhone',
    type: 'phone',
  },
  localExt: {
    name: 'localExt',
    type: 'phone',
  },
  contactAddressZip: {
    name: 'contactAddressZip',
    type: 'text',
  },
  contactAddressCounty: {
    name: 'contactAddressCounty',
    type: 'text',
  },
  contactAddressCity: {
    name: 'contactAddressCity',
    type: 'text',
  },
  contactAddressStreet: {
    name: 'contactAddressStreet',
    type: 'text',
  },
  residenceAddressZip: {
    name: 'residenceAddressZip',
    type: 'text',
  },
  residenceAddressCounty: {
    name: 'residenceAddressCounty',
    type: 'text',
  },
  residenceAddressCity: {
    name: 'residenceAddressCity',
    type: 'text',
  },
  residenceAddressStreet: {
    name: 'residenceAddressStreet',
    type: 'text',
  },
  note: {
    name: 'note',
  },
  description: {
    name: 'description',
  },
  description2: {
    name: 'description2',
  },
  /* member-company */
  companyName: {
    name: 'companyName',
    type: 'text',
  },
  companyUniformNumber: {
    name: 'companyUniformNumber',
    type: 'text',
  },
  companyAreaCode: {
    name: 'companyAreaCode',
    type: 'phone',
  },
  companyPhone: {
    name: 'companyPhone',
    type: 'phone',
  },
  companyExt: {
    name: 'companyExt',
    type: 'phone',
  },
  companyAddressZip: {
    name: 'companyAddressZip',
    type: 'text',
  },
  companyAddressCounty: {
    name: 'companyAddressCounty',
    type: 'text',
  },
  companyAddressCity: {
    name: 'companyAddressCity',
    type: 'text',
  },
  companyAddressStreet: {
    name: 'companyAddressStreet',
    type: 'text',
  },
  /* pet */
  petName: {
    name: 'petName',
    type: 'text',
  },
  petTypeId: {
    name: 'petTypeId',
    type: 'text',
  },
  petVarietyId: {
    name: 'petVarietyId',
    type: 'text',
  },
  icCard: {
    name: 'icCard',
    type: 'text',
    pattern: '[0-9]{0,}',
  },
  petWeight: {
    name: 'petWeight',
    type: 'text',
    pattern: '^\\d*\\.?\\d+$',
  },
  petBloodId: {
    name: 'petBloodId',
    type: 'text',
  },
  isBirthControl: {
    name: 'isBirthControl',
    //should be radio stuff
  },
  description: {
    name: 'description',
  },
  // same as member
  // genderId: {
  //   name: "genderId",
  //   type: "text",
  // },
  birthday: {
    name: 'birthday',
    // type: "date", //using date-picker ignore it yay!
  },
};
/**
 * @deprecated
 */
export default function getInputProp(name) {
  return inputProps[name];
}
