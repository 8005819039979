import { useState, useEffect } from 'react';
import format from 'date-fns/format';
import add from 'date-fns/add';
export default function Clock() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    let timeout = setTimeout(() => {
      setTime(prev => add(prev, { seconds: 1 }));
    }, 1000);

    return () => clearTimeout(timeout);
  }, [time]);

  return format(time, 'HH:mm');
}
