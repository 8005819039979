import { useOldPetService } from 'service';
import useToggle from 'libs/hooks/useToggle';
import formatToISODate from 'libs/date/formatToISODate';

import { styled } from '@mui/system';
import {
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Skeleton,
} from '@mui/material';
import GenderIcon from 'components/UI/GenderIcon';
import DeathStatusIcon from 'components/UI/DeathStatusIcon';
import usePacsURL from 'libs/hooks/usePacsURL';

import { InfoActionButton, MedicalRecordActionButton, ReservationActionButton, PacsActionButton } from 'features/ActionButton';

const StyledRow = styled(props => <TableRow hover {...props} />)({
  '& .MuiTableCell-root': {
    '&.MuiTableCell-head': {
      fontWeight: 'bold',
    },
    '&:last-of-type': {
      textAlign: 'right',
    },
  },
});

const DialogHeader = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: 24,
});

const CustomSkeleton = ({ isLoading }) => (isLoading ? <Skeleton /> : '--');

export default function PetInfoDialog({ petId, ButtonComponent }) {
  const [open, toggle] = useToggle(false);
  const { useOnePet } = useOldPetService();
  const { data: petData, isLoading } = useOnePet(petId, null, open);

  const petCode = petData?.petCode;
  const pacsUrl = usePacsURL(null, petCode, petData?.originPetCode);

  const originPetCode = petData?.originPetCode;
  const petName = petData?.petName || <CustomSkeleton isLoading={isLoading} />;
  const gender = petData?.genderId ? petData.gender.genderName : '--';
  const petType = petData?.petTypeId ? petData.petType.typeName : <CustomSkeleton isLoading={isLoading} />;
  const petVariety = petData?.petVarietyId ? petData.petVariety.varietyName : <CustomSkeleton isLoading={isLoading} />;
  const birthday = petData?.birthday || <CustomSkeleton isLoading={isLoading} />;
  // const age = convertDateToAge(birthday);
  const isBirthControl = Boolean(Number(petData?.isBirthControl)) ? '是' : '否';
  const bloodType = petData?.bloodTypeId ? petData.bloodType.bloodTypeName : '--';
  const icCard = petData?.icCard || <CustomSkeleton isLoading={isLoading} />;
  const description = petData?.description || <CustomSkeleton isLoading={isLoading} />;
  const createTime = petData?.createTime ? formatToISODate(petData?.createTime, true) : <CustomSkeleton isLoading={isLoading} />;

  const killUserId = petData?.killUserId || null;
  const killUserName = petData?.killUserName || null;
  const killTime = petData?.killTime || null;

  return (
    <>
      <ButtonComponent onClick={toggle} />
      <Dialog open={open} onClose={toggle.toFalse} maxWidth="sm" fullWidth>
        <DialogHeader>
          <DialogTitle>
            {petName}
            <DeathStatusIcon killUserId={killUserId} killUserName={killUserName} killTime={killTime} />
          </DialogTitle>
          <Stack direction="row">
            <MedicalRecordActionButton petId={petId} />
            {pacsUrl && <PacsActionButton anchor={pacsUrl} />}
            <ReservationActionButton prePetId={petId} />
            <InfoActionButton petId={petId} />
          </Stack>
        </DialogHeader>

        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <StyledRow>
                  <TableCell>寵物資訊</TableCell>
                  <TableCell>
                    <Stack>
                      <span>{petCode}</span>
                      <span>{originPetCode}</span>
                    </Stack>
                  </TableCell>
                </StyledRow>
              </TableHead>
              <TableBody>
                <StyledRow>
                  <TableCell>名稱</TableCell>
                  <TableCell>
                    {petName} <GenderIcon gender={gender} sx={{ width: 24, height: 24 }} />
                  </TableCell>
                </StyledRow>
                <StyledRow>
                  <TableCell>種類</TableCell>
                  <TableCell>{petType}</TableCell>
                </StyledRow>
                <StyledRow>
                  <TableCell>品種</TableCell>
                  <TableCell>{petVariety}</TableCell>
                </StyledRow>
                <StyledRow>
                  <TableCell>生日</TableCell>
                  <TableCell>
                    {birthday}
                    {/* ({age}歲) */}
                  </TableCell>
                </StyledRow>
                <StyledRow>
                  <TableCell>節育</TableCell>
                  <TableCell>{isBirthControl}</TableCell>
                </StyledRow>
                <StyledRow>
                  <TableCell>血型</TableCell>
                  <TableCell>{bloodType}</TableCell>
                </StyledRow>
                <StyledRow>
                  <TableCell>晶片</TableCell>
                  <TableCell>{icCard}</TableCell>
                </StyledRow>
                <StyledRow>
                  <TableCell>備註</TableCell>
                  <TableCell sx={{ whiteSpace: 'pre-wrap' }}>{description}</TableCell>
                </StyledRow>
                <StyledRow>
                  <TableCell>建立時間</TableCell>
                  <TableCell>{createTime}</TableCell>
                </StyledRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
}
