import { MenuItem, Select, ListSubheader, SelectChangeEvent, FormControl, InputLabel, TextField } from "@mui/material";
import { ChangeEvent } from "react";
import { useDepartmentService } from "service";

export default function SelectDepartment({
  value,
  onChange,
}: {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, nv: string) => void;
}) {
  const { useListDepartment } = useDepartmentService();
  const { data: departments } = useListDepartment({ query: new URLSearchParams({ limit: "zero" }) });

  return (
    // <FormControl size="small">
    //   <InputLabel>選擇部門</InputLabel>
    //   <Select value={value} label="選擇部門" onChange={e => onChange(e, e.target.value)}>
    //     {departments && departments.list.map(item => renderUserDepartmentMenuItems(item))}
    //   </Select>
    // </FormControl>
    <TextField size="small" select label="選擇部門" value={value ?? ""} onChange={e => onChange(e, e.target.value)}>
      <MenuItem value="">清除</MenuItem>
      {departments && departments.list?.map(item => renderUserDepartmentMenuItems(item))}
    </TextField>
  );
}

function renderUserDepartmentMenuItems(data: API.Department, layer = 0): React.ReactNode {
  if (data.subDepartment.length > 0) {
    return [
      <ListSubheader key={data.id} sx={{ paddingLeft: 2 + layer * 2, lineHeight: "36px" }}>
        {data.name}
      </ListSubheader>,
      (() => {
        ++layer;
        return data.subDepartment.map(item => renderUserDepartmentMenuItems(item, layer));
      })(),
    ];
  } else {
    return (
      <MenuItem key={data.id} value={data.id} sx={{ paddingLeft: 2 + layer * 2 }}>
        {data.name}
      </MenuItem>
    );
  }
}
