import { styled } from '@mui/system';
import { Chip, Avatar, Tooltip } from '@mui/material';
import PetIcon from '@mui/icons-material/Pets';

const PetAvatarChip = styled(
  props => (
    <Tooltip title={props.label}>
      <Chip
        {...props}
        size="small"
        color="info"
        // label={props.label.length > 10 ? props.label.slice(0, 10) + "..." : props.label}
        avatar={
          <Avatar>
            <PetIcon fontSize="small" />
          </Avatar>
        }
      />
    </Tooltip>
  ),
  { shouldForwardProp: prop => prop !== 'isDeath' },
)(({ theme, isDeath }) => ({
  marginRight: 4,
  marginBottom: 2,
  opacity: isDeath ? 0.5 : 1,
  justifyContent: 'space-between',
  '& .MuiAvatar-root': {
    background: 'white',
    color: theme.palette.info.main,
    '& svg': {
      width: 14,
      height: 14,
    },
  },
}));

export default PetAvatarChip;
