import { styled } from '@mui/system';
import { Typography } from '@mui/material';

const Brand = styled(Typography)(({ theme, color = 'primary', variant = 'contained', children, sx, ...props }) => ({
  borderRadius: 2,
  padding: '2px 6px',
  backgroundColor: theme.palette[color].main,
  color: theme.palette[color].contrastText,
  fontSize: 12,
  fontWeight: 'bold',
  width: 'fit-content',
  height: 'fit-content',
  whiteSpace: 'nowrap',
  ...(variant === 'contained' && {
    border: '1px solid',
    borderColor: 'transparent',
  }),
  ...(variant === 'outlined' && {
    border: '1px solid',
    borderColor: theme.palette[color].main,
    backgroundColor: 'transparent',
    color: theme.palette[color].main,
  }),

  ...props,
}));

export default Brand;
