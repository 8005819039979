import { Tooltip, IconButton } from '@mui/material';
import Icon from 'components/Icons';
/**
 * @deprecated
 */
export default function TooltipIconButton({
  title,
  icon,
  type,
  placement,
  anchor,
  size,
  buttonType,
  ...props
}) {
  return (
    <Tooltip title={title} disableInteractive placement={placement}>
      {anchor ? (
        <a href={anchor} target="_blank" rel="noreferrer noopener">
          <IconButton size={size} type={buttonType} {...props}>
            {icon || <Icon type={type} size={size} />}
          </IconButton>
        </a>
      ) : (
        <span>
          <IconButton size={size} type={buttonType} {...props}>
            {icon || <Icon type={type} size={size} />}
          </IconButton>
        </span>
      )}
    </Tooltip>
  );
}
