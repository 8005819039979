import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';

export default function GenderIcon({ gender, ...props }) {
  switch (gender?.toUpperCase()) {
    case '公':
      return <MaleIcon color="primary" {...props} />;
    case '男生':
      return <MaleIcon color="primary" {...props} />;
    case 'MALE':
      return <MaleIcon color="primary" {...props} />;
    case '母':
      return <FemaleIcon color="error" {...props} />;
    case '女生':
      return <FemaleIcon color="error" {...props} />;
    case 'FEMALE':
      return <FemaleIcon color="error" {...props} />;
    default:
      return null;
  }
}
