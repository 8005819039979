import { toast } from "react-toastify";

// /**
//  * @typedef {Object} ToastOptions
//  * @prop {*} pending
//  * @prop {*} success
//  * @prop {*} error
//  */

// /**
//  * @param {()=> Promise<void>} cbPromise
//  * @param {?ToastOptions} toastOptions
//  * @returns
//  */
export default function toastPromise(cbPromise, toastOptions = {}) {
  return () =>
    toast.promise(cbPromise(), {
      pending: "執行中",
      success: "操作成功",
      error: "操作失敗",
      ...toastOptions,
    });
}
